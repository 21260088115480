<!-- 我的发布广告 -->
<template>
    <section class="page container">
        <div class="top-link"></div>
        <div class="ad-order">
            <div class="order-filter-box">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <!-- <el-form-item label="币种">
                        <el-select v-model="formInline.region" placeholder="所有币种">
                            <el-option label="所有币种" value="all"></el-option>
                            <el-option label="待付款" value="shanghai"></el-option>
                            <el-option label="待放币" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="状态">
                        <el-select v-model="formInline.region" placeholder="所有状态">
                            <el-option label="所有状态" value="all"></el-option>
                            <el-option label="上架" value="shanghai"></el-option>
                            <el-option label="下架" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>

                    <el-form-item label="交易类型">
                        <el-select v-model="formInline.region" placeholder="全部类型">
                            <el-option label="全部类型" value="all"></el-option>
                            <el-option label="购买" value="shanghai"></el-option>
                            <el-option label="出售" value="beijing"></el-option>
                        </el-select>
                    </el-form-item> -->
                    <!-- <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item> -->
                </el-form>
            </div>
            <div class="table">
                <el-table :data="tableData" class="table-box" style="width: 1200px">
                    <el-table-column prop="id" :label="$t('c2c.ad_id')"></el-table-column>
                    <el-table-column prop="currency" :label="$t('c2c.ad_currency')"> </el-table-column>
                    <el-table-column prop="sellOrder" :label="$t('c2c.sell_type')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sellOrder === true ? $t('c2c.sell') : $t('c2c.buy') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="exchangeRate" :label="$t('c2c.exchangeRate')">
                        <!-- <template slot-scope="scope">
                            <span>{{ scope.row.exchangeRate && scope.row.amount ? scope.row.exchangeRate * scope.row.amount : '' }}</span>
                        </template> -->
                    </el-table-column>
                    <el-table-column prop="exchangeRate" :label="$t('c2c.ad_exchangeRate')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.minAmount + '-' + scope.row.maxAmount }}</span>
                        </template>
                    </el-table-column>
                    <!-- <el-table-column prop="completedOrders" label="完成订单数"> </el-table-column> -->
                    <el-table-column prop="frozenAmount" :label="$t('c2c.frozenAmount')"> </el-table-column>
                    <!-- <el-table-column prop="totalAmount" label="总金额"> </el-table-column> -->
                    <!-- <el-table-column prop="totalOrders" label="订单数"> </el-table-column> -->

                    <!-- <el-table-column prop="paymentTypes" label="支付方式">
                        <template slot-scope="scope">
                            <span>{{ getPaymentName(scope.row.paymentTypes) }}</span>
                        </template>
                    </el-table-column> -->

                    <!-- <el-table-column prop="enabled" label="状态">
                        <template slot-scope="scope">
                            <span>{{ scope.row.enabled === true ? '启用' : '关闭' }}</span>
                        </template>
                    </el-table-column> -->
                    <el-table-column prop="fiatSymbol" :label="$t('c2c.fiatSymbol')"> </el-table-column>
                    <el-table-column prop="timeCreated" :label="$t('c2c.timeCreated')" width="180">
                        <template slot-scope="scope">
                            <span>{{ new Date(scope.row.timeCreated).formatDateTime() }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('c2c.order_operate')" width="180">
                        <template slot-scope="scope">
                            <el-button type="text" v-if="scope.row.status !== 2" @click="setStatus(scope.row)">{{
                                scope.row.status === 0 ? $t('c2c.ad_off') : $t('c2c.ad_on')
                            }}</el-button>
                            <el-button type="text" @click="changeAds(scope.row)">{{ $t('c2c.ad_change') }}</el-button>
                            <el-button type="text" v-if="scope.row.status !== 2" @click="closeMerchantAds(scope.row.id)">{{ $t('c2c.ad_close') }}</el-button>
                            <el-button type="text" disabled v-if="scope.row.status === 2">{{ $t('c2c.ad_closed') }}</el-button>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-mobile">
                    <div class="body">
                        <div class="card-item" v-for="(item, index) in tableData" :key="index">
                            <div>
                                <div class="label">{{ $t('c2c.ad_id') }}:</div>
                                <div class="val">{{ item.id }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.ad_currency') }}:</div>
                                <div class="val">{{ item.currency }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.sell_type') }}:</div>
                                <div class="val">{{ item.sellOrder === true ? $t('c2c.sell') : $t('c2c.buy') }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.price') }}:</div>
                                <div class="val">{{ item.exchangeRate }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.ad_exchangeRate') }}:</div>
                                <div class="val">{{ item.minAmount + '-' + item.maxAmount }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.frozenAmount') }}:</div>
                                <div class="val">{{ item.frozenAmount }}</div>
                            </div>
                            <!-- <div>
                                <div class="label">总金额:</div>
                                <div class="val">{{ item.totalAmount }}</div>
                            </div>
                            <div>
                                <div class="label">订单数:</div>
                                <div class="val">{{ item.totalOrders }}</div>
                            </div> -->
                            <div>
                                <div class="label">{{ $t('c2c.fiatSymbol') }}:</div>
                                <div class="val">{{ item.fiatSymbol }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.timeCreated') }}:</div>
                                <div class="val">{{ new Date(item.timeCreated).formatDateTime() }}</div>
                            </div>
                            <div>
                                <div class="label"></div>
                                <div class="val button-box">
                                    <el-button @click="setStatus(item)">{{ item.status ? $t('c2c.ad_off') : $t('c2c.ad_on') }}</el-button>
                                    <el-button @click="changeAds(item)">{{ $t('c2c.ad_change') }}</el-button>
                                    <el-button @click="closeMerchantAds(item.id)">{{ $t('c2c.ad_close') }}</el-button>
                                </div>
                            </div>

                            <!-- <div>
                                <div class="label"></div>
                                <div class="val"><router-link :to="`/trade/order?orderType=merchant&orderId=${item.orderId}`">查看</router-link></div>
                            </div> -->
                        </div>
                    </div>
                </div>
                <div v-if="!(tableData.length === 0)">
                    <el-button v-if="!noMoreData" class="more-btn" block @click="more()" :loading="moreLoading">{{ $t('c2c.check_more') }}</el-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    data() {
        return {
            formInline: {
                status: -1
            },
            tableData: [],
            noMoreData: false,
            moreLoading: false
        }
    },
    created() {
        this.getTableOrders()
    },
    mounted() {},
    methods: {
        changeAds(data) {
            // this.$router.push('/tradeAd?id=')
            console.log(data)
            this.$router.push({
                path: '/tradeAd',
                query: {
                    ...data
                }
            })
        },
        closeMerchantAds(id) {
            this.$confirm(`${this.$t('c2c.ad_confirm')}`, `${this.$t('c2c.ad_id')} ${id}`, {
                confirmButtonText: this.$t('c2c.confirm'),
                cancelButtonText: this.$t('c2c.cancel'),
                type: 'warning'
            })
                .then(() => {
                    $.callPostApi(this, '/api/v1/c2c/merchant/updateads', { id }).then((res) => {
                        if(res.errcode === 0){
                            this.$message({
                                type: 'success',
                                message: this.$t('c2c.ad_success_close')
                            })
                            this.tableData.forEach((item) => {
                                if (item.id === id) {
                                    item.status = 2
                                }
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                })
                .catch(() => {})
        },
        setStatus(data) {
            const { id, status, exchangeRate, minAmount, maxAmount } = data

            this.$confirm(` ${status === 0 ? this.$t('c2c.ad_off_tip') : this.$t('c2c.ad_on_tip')} `, `${this.$t('c2c.ad_id')} ${id}`, {
                confirmButtonText: this.$t('c2c.confirm'),
                cancelButtonText: this.$t('c2c.cancel'),
                type: 'warning'
            })
                .then(() => {
                    const data = {
                        id,
                        status: status === 0 ? 1 : 0,
                        exchangeRate,
                        minAmount,
                        maxAmount
                    }
                    $.callPostApi(this, '/api/v1/c2c/merchant/updateads', data).then((res) => {
                        if(res.errcode === 0){
                            this.$message({
                                type: 'success',
                                message: `${status === 0 ? this.$t('c2c.ad_off_success') : this.$t('c2c.ad_on_success')}`
                            })
                            this.tableData.forEach((item) => {
                                if (item.id === id) {
                                    item.status === 0 ? (item.status = 1) : (item.status = 0)
                                }
                            })
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                })
                .catch(() => {})
        },
        onSubmit() {
            this.getTableOrders()
        },
        handleClick(row) {
            console.log(row)
        },
        getTableOrders(maxId = 0x7fffffff) {
            $.callGetApi(this, `/c2c/merchant/ads?maxId=${maxId}`).then((res) => {
                if(res.errcode === 0){
                    console.log(res)
                    let data = res.data
                    if (data.length < 20) {
                        this.noMoreData = true
                    } else {
                        this.noMoreData = false
                    }
                    data.forEach((item) => {
                        this.tableData.push(item)
                    })
                    this.moreLoading = false
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
        more() {
            this.moreLoading = true
            let maxId = this.tableData[this.tableData.length - 1].id - 1
            this.getTableOrders(maxId)
        },
        // 获取用户支持支付方式
        getPaymentName(payType) {
            let data
            if (this.sysconfig.c2c) {
                data = this.sysconfig.c2c.paymentTypes.find((item) => {
                    return item.key === payType && item.enabled
                })
            }
            if (data) {
                return data.name
            }
        }
    }
}
</script>
<style lang="less" scoped>
.top-link {
    height: 56px;
    line-height: 56px;
}
.ad-order {
    max-width: 1136px;
    margin-bottom: 30px;

    .table {
        max-width: 1136px;
    }
    .el-button.el-button--primary {
        line-height: normal;
    }
}
.table-box {
    // width: 900px;
}
.table-mobile {
    display: none;
}
@media (max-width: 768px) {
    .table-box {
        display: none;
    }
    .table-mobile {
        display: block;
        margin: 0 20px;
        width: auto;
        // padding: 0 20px;
    }
    .card-item {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0px;
        padding: 25px 15px 16px;
        position: relative;
        font-size: 15px;
        > div {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
        }
    }
    /deep/.button-box /deep/.el-button + .el-button,
    .el-checkbox.is-bordered + .el-checkbox.is-bordered {
        margin-left: 0;
        margin-right: 10px;
    }
    /deep/.button-box .el-button {
        margin-bottom: 6px;
    }
}
.more-btn {
    display: block;
    width: 100%;
    box-shadow: none;
}
</style>
