<template>
    <form @submit="upload_photos" action="javascript:void(0)" method="post" enctype="multipart/form-data" class="form-padding">
        <div class="form-group row">
            <div class="col">
                <div class="help-block">{{ $t('idverification.upload_desp') }}</div>
            </div>
        </div>
        <div class="row">
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col">
                        <label class="form-label">{{ $t('idverification.label_realname') }}</label>
                        <input type="text" name="RealName" maxlength="64" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                    </div>
                </div>
            </div>
            <div class="col-md-6">
                <div class="form-group row">
                    <div class="col">
                        <label class="form-label">{{ $t(sysconfig.is_hkmy ? 'idverification.label_id_number_my' : 'idverification.label_id_number') }}</label>
                        <input type="text" name="IDNumber" maxlength="18" class="form-control" autocomplete="off" data-val="true" data-val-required="*" />
                    </div>
                </div>
            </div>
        </div>
        <div class="form-group row">
            <div class="col-md-6">
                <photo-uploader-component name="IDCard_Front" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_front_my' : 'idverification.upload_front')" />
            </div>
            <div class="col-md-6">
                <photo-uploader-component name="IDCard_Back" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_back_my' : 'idverification.upload_back')" />
            </div>
            <div class="col-md-6">
                <!-- Is the holding picture requried or not? -->
                <photo-uploader-component v-if="profile.requiredIDLevel === 4" name="HoldingPhoto" :title="$t(sysconfig.is_hkmy ? 'idverification.upload_hold_my' : 'idverification.upload_hold')" />
            </div>
        </div>

        <div class="row">
            <div class="col-md-4">
                <submit-button ref="submitButton" :text="$t('idverification.label_submit')" />
            </div>
        </div>
    </form>
</template>

<script>
import PhotoUploaderComponent from './UploadID_PhotoUploader.vue';
import compressed from 'utilities/Compressed'
export default {
    components: { PhotoUploaderComponent },

    props: ['profile'],

    created() {
        const profile = this.profile;
        if (!profile || typeof profile.requiredIDLevel !== 'number') throw new Error('A valid profile must be specified.');
    },

    mounted() {
        $.resetValidators();
    },

    methods: {
        upload_photos: function (e) {
            const self = this;
            const frm = e.target;
            if ($(frm).valid()) {
                // all files are required.
                let valid = true;
                $(':file').each(function () {
                    if (!this.value || !this.value.length) valid = false;
                });

                if (!valid) {
                    $.top_error(self.$t('idverification.incomplete_photos'));
                } else {
                    const _do_post =async function () {
                        let frmData = new FormData(frm);
                        const photo_ids = ['IDCard_Front', 'IDCard_Back', 'HoldingPhoto'];
                        for (let i = 0; i < photo_ids.length; i++) {
                            const photo_key = photo_ids[i];
                            const oldPhoto = frmData.get(photo_key);
                            if (oldPhoto) {
                                try {
                                    // No need to compress the photo if its size is smaller than 200k
                                    let currentSize = parseInt(oldPhoto['size']);
                                    if (currentSize > 200 * 1024) {
                                        const updated = await compressed.compressImg(oldPhoto);
                                        frmData.set(photo_key, updated);
                                    }
                                } catch (err) {
                                    // The error could be ignored.
                                    console.error(`Failed to compress ${photo_key}: ${err}`);
                                }
                            }
                        }
                        $.ajax({
                            url: g_server_root + '/api/v1/identityverification',
                            type: 'POST',
                            data: frmData,
                            processData: false,
                            contentType: false,
                            success: function (json) {
                                if (json && json.errcode === 0) {
                                    $.top_alert(self.$t('idverification.uploaded'));
                                    setTimeout(()=>{
                                        self.getBindBankStatus();
                                    },2000)
                                    self.$emit('submitted');
                                } else {
                                    $.top_error(json.errmsg);
                                    self.$refs.submitButton.reset();
                                }
                            },
                            error: function (err) {
                                let err_msg;
                                if (err && typeof err.responseJSON !== 'undefined') err_msg = err.responseJSON.errmsg;
                                else err_msg = self.$t('idverification.upload_error');

                                $.top_error(err_msg);
                                self.$refs.submitButton.reset();
                            }
                        });
                    };

                    self.$refs.submitButton.submit();
                    setTimeout(_do_post, 200);
                }
            }
        },
        async getBindBankStatus(){
            const json = await $.callGetApi(this, '/api/v1/bankaccount/list?id=bank');
            if (json.errcode === 0 && json.data.length === 0) {
                this.$router.push('/user/bankaccount');
            } else {
                this.$router.push('/user');
            }
        },
    }
};
</script>
