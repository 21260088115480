<!-- 添加收款方式 -->
<template>
    <section class="page">
        <div class="top-link"></div>
        <div class="c2c-payment-add-bank">
            <div class="add-bank-header">
                <div class="title">{{ $t('c2c.bank_title') }}</div>
            </div>
            <div class="add-bank-form">
                <div class="form-box">
                    <div class="form">
                        <div class="type-title">
                            <div class="title"><span class="line"></span>{{ $t('c2c.bank_info') }}</div>
                        </div>
                        <el-form ref="form" :model="form" label-width="80px" label-position="top" :rules="bankRules">
                            <el-form-item :label="$t('c2c.accountName')">
                                <el-input style="display: none" v-model="form.accountName"></el-input>
                                <div class="name">{{ form.accountName }}</div>
                            </el-form-item>
                            <!-- <el-form-item label="账号信息">
                                <el-input
                                    v-model="form.accountNo"
                                    type="textarea"
                                    :rows="6"
                                    placeholder="请输入账号信息,如姓名、银行账号/卡号、银行名称、开户支行(选填)"
                                ></el-input>
                            </el-form-item> -->
                            <el-form-item :label="$t('c2c.bank_key')" prop="key">
                                <el-select v-model="form.key">
                                    <el-option v-for="(type, key) in paymentTypes" :key="key" :label="type.name" :value="type.key"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.accountNo')" prop="accountNo">
                                <el-input v-model="form.accountNo"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.bankName')" prop="bankName">
                                <el-input v-model="form.bankName"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.branch')" prop="branch">
                                <el-input v-model="form.branch"></el-input>
                            </el-form-item>

                            <el-form-item class="form-btn">
                                <el-button class="submit" type="primary" @click="submitForm('form')" :loading="false">{{ $t('c2c.confirm') }}</el-button>
                                <el-button class="canel" @click="goBack()">{{ $t('c2c.cancel') }}</el-button>
                            </el-form-item>
                        </el-form>
                    </div>
                    <div class="btn"></div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    data() {
        return {
            paymentTypes: null,
            form: {
                key: '',
                accountName: '',
                // AccountData: ''
                accountNo: '',
                bankName: '',
                branch: ''
            },
            bankRules: {
                // AccountData: [{ required: true, message: '账号信息', trigger: 'blur' }]
                accountNo: [{ required: true, message: this.$t('c2c.accountNo_error'), trigger: 'blur' }],
                bankName: [{ required: true, message: this.$t('c2c.bankName_error'), trigger: 'blur' }]
            }
        }
    },
    created() {
        $.callGetApi(this, '/api/v1/c2c/merchant').then((res) => {
            if(res.errcode === 0){
                const { realName } = res.data
                // this.paymentTypes = this.$ti
                // console.log(paymentTypes)
                this.form.accountName = realName
                // this.form.key = paymentTypes[0].key
            }
        })
        .catch((err) => {
            console.log(err)
        })
        if (this.sysconfig.c2c && this.sysconfig.c2c.paymentTypes) {
            const paymentTypes = this.sysconfig.c2c.paymentTypes
            this.paymentTypes = paymentTypes
            this.form.key = paymentTypes[0].key
        }
        let data = this.$route.query.accountData
        if (data) {
            data = JSON.parse(data)
            this.form = Object.assign(this.form, data)
        }
    },
    mounted() {},
    methods: {
        submitForm(ref) {
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    let data = { key: this.form.key, AccountData: JSON.stringify(this.form) }
                    $.callPostApi(this, '/api/v1/c2c/merchant/paymentType', data).then((res) => {
                        if(res.errcode === 0){
                            console.log(res)
                            this.$message({ message: this.$t('c2c.add_success'), type: 'success' })
                            this.$router.push('/userCenter')
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        goBack() {
            window.history.back()
        }
    }
}
</script>
<style lang="less" scoped>
.c2c-payment-add-bank{
    width: -webkit-fill-available;
    width: -moz-available;
}
.add-bank-header {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    background: #fff;
    position: relative;
    .title {
        padding-left: 16px;
        margin: 0px auto;
        max-width: 1136px;
        font-size: 24px;
        padding: 20px 0px;
        font-weight: 600;
        text-indent: 16px;
    }
}
.add-bank-form {
    background-color: #f9f9fa;
    padding: 16px;
    .type-title {
        margin-bottom: 24px;
        .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .line {
                display: inline-block;
                margin: 0px 12px 0px 0px;
                width: 4px;
                height: 14px;
                border-radius: 4px;
                background-color: #f0b90b;
            }
        }
    }
    .form {
        max-width: 512px;
        margin: 0px auto;
        width: 100%;
    }
    /deep/.el-input.is-active .el-input__inner,
    /deep/.el-select .el-input.is-focus .el-input__inner,
    /deep/.el-input__inner:focus {
        border-color: #f0b90b;
    }

    /deep/.el-form--label-top .el-form-item__label {
        padding: 0;
        height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        padding: 0;
    }
    .form-btn {
    }
}
.form-box {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    box-shadow: rgba(20, 21, 26, 0.04) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 40px 24px;
}
@media screen and (min-width: 767px) {
    .add-bank-form {
        padding: 16px 24px;
    }
}
@media screen and (max-width: 767px) {
    .form-box {
        padding-top: 20px;
    }
}
@media screen and (min-width: 1023px) {
    .add-bank-form {
        padding: 16px 32px;
    }
}
</style>
