<!-- 用户中心 -->
<template>
    <section class="page">
        <!-- <div class="top-link"></div> -->
        <div class="c2c-user-center">
            <div class="user-content">
                <div class="user-info">
                    <div class="user-left">
                        <div class="info">
                            <div class="small"><div class="letter">a</div></div>
                            <div class="verified">
                                <div class="m">
                                    <div class="mail">{{ realName }}</div>
                                    <div class="tip">Verified User</div>
                                </div>
                                <div class="status">
                                    <div class="s">{{ $t('c2c.email') }} <i class="el-icon-success"></i></div>
                                    <div class="s">{{ $t('c2c.user_id') }} <i class="el-icon-success"></i></div>
                                </div>
                            </div>
                        </div>
                    </div>
                    <div class="user-right">
                        <div class="merchant-btn">
                            <!-- <router-link class="link" to="/merchantApplication">成为广告方</router-link> -->
                            <router-link class="link" to="/merchantOrder">{{ $t('c2c.order_history') }}</router-link>
                            <router-link class="link" to="/myAd">{{ $t('c2c.my_ad') }}</router-link>
                            <router-link class="link" to="/tradeAd">{{ $t('c2c.trade_ad') }}</router-link>
                        </div>
                    </div>
                </div>
                <hr class="hr" />
                <div class="trade-data">
                    <div class="d">
                        <p class="p">{{ $t('c2c.user_trade') }}</p>
                        <div class="nums">
                            <span>{{ completedOrders }}</span
                            >次
                        </div>
                    </div>
                    <div class="d">
                        <p class="p">{{ $t('c2c.user_completionRate') }}</p>
                        <div class="nums">
                            <span>{{ completionRate }}</span
                            >%
                        </div>
                    </div>
                    <div class="d">
                        <p class="p">{{ $t('c2c.user_timeCreated') }}</p>
                        <div class="nums">{{ this.timeCreated }}</div>
                    </div>
                    <div class="d">
                        <p class="p">{{ $t('c2c.user_totalOrder') }}</p>
                        <div class="nums">
                            <span>{{ totalOrders }}</span
                            >次
                            <!-- <div class="other">(买入 0 | Sell 0)</div> -->
                        </div>
                    </div>
                </div>
            </div>
            <div class="payment-container">
                <div class="payment">
                    <div class="top">
                        <h3 class="title">{{ $version === 'v11' ? 'OTC' : 'C2C' }} {{ $t('c2c.fiatSymbol') }}</h3>
                        <div class="p-bottom">
                            <p class="p">
                                {{ $t('c2c.user_desc') }}
                            </p>
                            <div class="right-link">
                                <router-link to="/paymentAddBank" class="link"> +{{ $t('c2c.bank_title') }} </router-link>
                            </div>
                        </div>
                    </div>
                    <div class="card-list">
                        <div class="list" v-for="(card, key) in paymentTypes" :key="key">
                            <div class="top">
                                <div class="title"><span class="line"></span>{{ card.name }}</div>
                                <div class="btn-box">
                                    <!-- <div class="edit" @click="">修改</div> -->
                                    <router-link :to="{ path: '/paymentAddBank', query: { accountData: JSON.stringify(card.accountData) } }" class="link">{{
                                        $t('c2c.ad_change')
                                    }}</router-link>
                                </div>
                            </div>
                            <div class="content">
                                <div class="p">
                                    <div class="label">{{ $t('c2c.accountName') }}</div>
                                    <p>{{ realName }}</p>
                                </div>
                                <div class="p">
                                    <div class="label">{{ $t('c2c.accountNo') }}</div>
                                    <p>{{ card.accountData.accountNo }}</p>
                                </div>
                                <div class="p">
                                    <div class="label">{{ $t('c2c.bankName') }}</div>
                                    <p>{{ card.accountData.bankName }}</p>
                                </div>
                            </div>
                        </div>
                        <!-- <div class="list">
                            <div class="top">
                                <div class="title"><span class="line"></span>银行卡</div>
                                <div class="btn-box">
                                    <div class="edit">编辑</div>
                                    <div class="delete">删除</div>
                                </div>
                            </div>
                            <div class="content">
                                <div class="p">
                                    <div class="label">姓名</div>
                                    <p>张三</p>
                                </div>
                                <div class="p">
                                    <div class="label">银行卡/账号</div>
                                    <p>622222222222</p>
                                </div>
                                <div class="p">
                                    <div class="label">银行名称</div>
                                    <p>工商银行</p>
                                </div>
                            </div>
                        </div> -->
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
// import Coin from '@/app/Components/Coin.vue'
export default {
    // components: { Coin, List, ListMobile, Card },
    data() {
        return {
            realName: null,
            paymentTypes: [],
            timeCreated: null,
            completedOrders: null,
            totalOrders: null,
            completionRate: null,
            status: null //商户状态： 1：正常 2：暂停  3：关闭
        }
    },
    created() {
        $.callGetApi(this, '/api/v1/c2c/merchant').then((res) => {
            if(res.errcode === 0){
                const { completedOrders, totalOrders, status, timeCreated, realName } = res.data
                this.completedOrders = completedOrders
                this.totalOrders = totalOrders
                this.completionRate = isNaN(completedOrders / totalOrders) ? 0 : ((completedOrders / totalOrders) * 100).toFixed(2)
                this.status = status
                this.realName = realName
                this.timeCreated = new Date(timeCreated).formatDate()
            }
        })
        .catch((err) => {
            console.log(err)
        })
        $.callGetApi(this, '/api/v1/c2c/merchant/paymentTypes').then((res) => {
            if(res.errcode === 0){
                let paymentTypes = []
                res.data.forEach((item) => {
                    paymentTypes.push({ ...item, key: item.key, accountData: JSON.parse(item.accountData) })
                })
                this.paymentTypes = paymentTypes
            }
        })
        .catch((err) => {
            console.log(err)
        })
    },
    mounted() {},
    methods: {}
}
</script>
<style lang="less" scoped>
.top-link {
    height: 56px;
    line-height: 56px;
}
.c2c-user-center {
    margin: 0px;
    min-width: 0px;
    display: flex;
    flex-direction: column;
    .hr {
        margin: 24px 0px;
        min-width: 0px;
        width: 100%;
        height: 1px;
        border: none;
        background-color: #eaecef;
    }
}
.user-content {
    margin: 0px;
    min-width: 0px;
    display: flex;
    background: white;
    padding: 16px;
    box-shadow: rgb(0 0 0 / 8%) 0px 0px 4px;
    flex-direction: column;
}
.user-info {
    min-width: 0px;
    display: flex;
    justify-content: space-between;
    .user-left {
        margin: 0px;
        min-width: 0px;
        display: flex;
        gap: 16px;
        flex-direction: column;
        -webkit-box-pack: justify;
        justify-content: space-between;
    }
    .info {
        margin: 0px;
        min-width: 0px;
        display: flex;
        .small {
            margin: 0px;
            min-width: 24px;
            height: 100%;
            .letter {
                margin: 0px;
                min-width: 0px;
                display: flex;
                border-radius: 50%;
                background-color: #4653c8;
                font-size: 16px;
                text-align: center;
                color: white;
                width: 24px;
                height: 24px;
                line-height: 24px;
                align-items: center;
                justify-content: center;
            }
        }
        .m {
            margin: 0;
            min-width: 0;
            display: flex;
            align-items: center;
        }
        .mail {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            font-weight: 500;
            font-size: 16px;
            line-height: 24px;
            color: #1e2329;
            margin-right: 10px;
        }
        .verified {
            margin: 0px 0px 0px 16px;
            min-width: 0px;
            display: flex;
            flex-direction: column;
            .tip {
                margin: 0px 0px 0px 10px;
                min-width: 0px;
                display: flex;
                align-items: center;
                justify-content: center;
                border-radius: 4px;
                padding: 2px 8px;
                background: #fef6d8;
                margin: 0px;
                min-width: 0px;
                font-weight: 400;
                font-size: 12px;
                line-height: 16px;
                color: #c99400;
            }
        }
        .status {
            margin: 0px;
            min-width: 0px;
            display: flex;
            box-sizing: border-box;
            margin: 8px 0px 0px;
            min-width: 0px;
            display: flex;
        }
        .s {
            margin-right: 16px;
            min-width: 0px;
            display: flex;
            align-items: center;
            font-size: 14px;
            i {
                display: inline-block;
                margin-left: 3px;
                color: #0ecb81;
            }
        }
    }
}

.merchant-btn {
    .link {
        padding: 0 12px;
        background: #fcd535;
        color: #181a20;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        font-weight: 500;
        text-align: center;
        line-height: 36px;
        min-width: 52px;
        border-radius: 4px;
        display: inline-block;
        height: 36px;
        box-sizing: border-box;
    }
}
.trade-data {
    display: flex;
    .d {
        flex: 1;
        margin-right: 15px;
        margin-bottom: 32px;
    }
    .p {
        height: 20px;
        line-height: 20px;
        color: #707a8a;
    }
    .nums {
        // height: 32px;
        line-height: 32px;
        vertical-align: bottom;
        span {
            font-size: 24px;
            color: #1e2329;
            font-weight: 500;
            padding-right: 4px;
        }
    }
    .other {
        margin: 0px;
        min-width: 0px;
        font-weight: 400;
        font-size: 12px;
        line-height: 16px;
        color: rgb(112, 122, 138);
    }
}
.payment-container {
    padding-left: 120px;
    padding-right: 120px;
    padding-top: 32px;
    background: #fafafa;
}
.payment {
    box-sizing: border-box;
    margin: 0px 0px 32px;
    min-width: 0px;
    display: flex;
    width: 100%;
    flex-direction: column;
    background: #ffffff;
    padding: 24px;
    box-shadow: none;
    border-radius: 4px;
    border: 1px solid #eaecef;
    min-height: 45vh;
    .title {
        margin: 0px 0px 16px;
        min-width: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        margin-bottom: 24px;
    }
    .p-bottom {
        min-width: 0px;
        display: flex;
        // align-items: flex-end;
        justify-content: space-between;
        .p {
            margin: 0px 10px 8px 0px;
            min-width: 0px;
            max-width: 100%;
            line-height: 24px;
            color: #212833;
        }
        .link {
            background: #eaecef;
            cursor: pointer;
            font-weight: 500;
            text-align: center;
            padding: 0 16px;
            height: 40px;
            line-height: 40px;
            min-width: 60px;
            color: #1e2329;
            border-radius: 4px;
            min-height: 24px;
            width: 259px;
            display: block;
        }
    }
}
.card-list {
    padding: 24px 0;
    .list {
        border-radius: 4px;
        border: 1px solid #e6e8ea;
        background: #fff;
        transition: box-shadow 0.3s ease 0s;
        margin-bottom: 16px;
        &:last-child {
            margin-bottom: 0;
        }
        &:hover {
            box-shadow: rgba(0, 0, 0, 0.078) 0px 4px 8px;
        }
    }
    .top {
        display: flex;
        align-items: center;
        background: #fafafa;
        height: 44px;
        padding: 0px 24px;
        justify-content: space-between;
        .title {
            margin: 0px;
            font-weight: 500;
            line-height: 20px;
            font-size: 14px;
            display: flex;
            align-items: center;
            .line {
                display: inline-block;
                margin: 0px 8px 0px 0px;
                min-width: 0px;
                width: 4px;
                height: 14px;
                border-radius: 4px;
                background-color: #f0b90b;
            }
        }
        .btn-box {
            display: flex;
            > div {
                min-width: 50px;
                padding: 10px;
                text-align: center;
                cursor: pointer;
            }
        }
    }
    .content {
        display: flex;
        flex-wrap: wrap;
        padding: 20px 24px;
        .p {
            flex: 1;
            margin-bottom: 24px;
            .label {
                margin-bottom: 14px;
                font-size: 14px;
                color: rgb(118, 128, 143);
            }
            p {
            }
        }
    }
}
@media screen and (min-width: 767px) {
    .user-content {
        padding: 32px 24px 16px;
    }
    .payment {
        box-shadow: rgba(0, 0, 0, 0.078) 0px 0px 4px, rgba(0, 0, 0, 0.078) 0px 2px 4px;
        border: none;
    }
}
@media screen and (min-width: 1023px) {
    .user-content {
        padding: 36px 120px 0px;
    }
    .user-left {
        flex-direction: row;
    }
    .user-right {
        align-items: flex-end;
    }
    .payment {
        box-shadow: rgba(0, 0, 0, 0.078) 0px 0px 4px, rgba(0, 0, 0, 0.078) 0px 2px 4px;
        .p-bottom {
            .p {
                max-width: 748px;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .user-info {
        // display: block;
        flex-direction: column;
        .user-left {
            margin-bottom: 30px;
        }
        .merchant-btn .link {
            margin-left: 40px;
        }
    }
    .trade-data {
        flex-direction: column;
        .d {
            display: flex;
            justify-content: space-between;
            margin-bottom: 10px;
            height: 24px;
            line-height: 24px;
            .nums {
                text-align: right;
                span {
                    font-size: 14px;
                }
            }
        }
    }
    .payment-container {
        padding: 0;
    }
    .payment {
        .p-bottom {
            display: block;
            .link {
                margin: 0 auto;
                margin-top: 20px;
            }
        }
    }
    .card-list .content {
        flex-direction: column;
        .p {
            display: flex;
            justify-content: space-between;
        }
    }
    .user-info {
        .merchant-btn {
            display: flex;
            flex-wrap: wrap;
            .link {
                margin-left: 0;
                margin-right: 10px;
                margin-bottom: 10px;
            }
        }
    }
}
</style>
