<template>
    <div class="container page">
        <!-- <Nav /> -->
        <div :class="$version + '-trade'" v-loading="loading">
            <div class="order-header">
                <div class="order-header-left">
                    <div class="status-text" v-if="orderDetail.status !== 4">
                        <!-- 您正在向 -->
                        <span>{{ isBuy ? $t('c2c.detail_seller') : $t('c2c.detail_buyer') }}</span>
                        <!-- 商户战术用户名，用户展示商户名 -->
                        <span>{{ isMerchant ? orderDetail.userName : orderDetail.merchantName }}</span>
                        <span> {{ isBuy ? $t('c2c.buy') : $t('c2c.sell') }} </span>
                        <span>{{ orderDetail.currency }}</span>
                    </div>
                    <div v-if="orderDetail.status === 4" class="status-text">
                        {{ $t('c2c.detail_cancel') }}
                        <i class="el-icon-warning" />
                    </div>
                    <div class="status-tips">
                        <span v-if="orderDetail.status === 4">{{ $t('c2c.detail_cancel_detail') }} </span>
                        <!-- <span v-if="orderDetail.status === 0 && !expired">订单已生成，请耐心等待系统确认。</span> -->
                        <div v-if="!expired && orderDetail.timeoutMS < 2592000000">
                            <i class="el-icon-time" />
                            <span>{{ orderExpireTime }}</span>
                        </div>
                    </div>
                </div>
                <div class="order-header-right">
                    <div class="order-top-info">
                        <span class="label">{{ $t('c2c.order_id') }}</span>
                        <span>{{ orderDetail.orderId }}</span>
                        <i class="el-icon-copy-document" @click="copyOrderNumber()" />
                    </div>
                    <div class="order-top-info">
                        <span class="label">{{ $t('c2c.detail_create_time') }} </span>
                        {{ new Date(orderDetail.timeCreated).formatDateTime(true) }}
                    </div>
                </div>
            </div>
            <div class="order-info">
                <div class="step-Line" v-if="!expired">
                    <el-steps :space="'30%'" :active="orderDetail.status" :align-center="true" finish-status="success">
                        <el-step :title="isBuy ? $t('c2c.detail_seller_pay') : $t('c2c.detail_buyer_pay')"></el-step>
                        <el-step :title="isBuy ? $t('c2c.detail_seller_coin') : $t('c2c.detail_buyer_coin')"></el-step>
                        <el-step :title="getStatusName"></el-step>
                    </el-steps>
                </div>
                <div class="step-info">
                    <div class="step-left-info">
                        <div class="order-label">{{ $t('c2c.detail_order_info') }}</div>
                        <div class="step-order-info">
                            <div>
                                <div class="step-label">{{ $t('c2c.detail_total') }}</div>
                                <div class="total-price">{{ orderDetail.fiatSymbol }}{{ (orderDetail.exchangeRate * orderDetail.amount).toFixed(2) }}</div>
                            </div>
                            <div>
                                <div class="step-label">{{ $t('c2c.price') }}</div>
                                <div>{{ orderDetail.fiatSymbol }}{{ orderDetail.exchangeRate }}</div>
                            </div>
                            <div>
                                <div class="step-label">{{ $t('c2c.amount') }}</div>
                                <div>{{ orderDetail.amount }}</div>
                            </div>
                        </div>
                        <div v-if="orderDetail.status <= 1 && !expired" class="order-label">
                            <span v-if="isBuy">{{ $t('c2c.detail_order_info1') }}</span>
                            <span v-if="!isBuy">{{ $t('c2c.fiatSymbol') }}</span>
                        </div>
                        <div v-if="orderDetail.status <= 1 && !expired" class="pay-methods">
                            {{ accountData.accountName }} {{ accountData.accountNo }} {{ accountData.bankName }} {{ accountData.branch }}
                            <!-- <div class="method-name">
                                <div class="name-text">{{ orderDetail.tradeMethodName }}</div>
                            </div>
                            <div class="method-info">
                                <div class="method-label">姓名</div>
                                <div class="method-text">
                                    {{ orderDetail.sellerName }}
                                    <i class="el-icon-copy-document" @click="copyOrderNumber()" />
                                </div>
                                <div class="method-label">{{ orderDetail.tradeMethodName }}账户</div>
                                <div class="method-text">
                                    {{ orderDetail.sellerNickname }}
                                    <i class="el-icon-copy-document" @click="copyOrderNumber()" />
                                </div>
                                <div class="method-label">二维码</div>
                                <div></div>
                            </div> -->
                        </div>
                        <!-- <div v-if="orderDetail.status <= 3 && orderDetail.tradeType === 'SELL'" class="order-label">买家付款凭证</div>
                        <div v-if="orderDetail.status <= 3 && orderDetail.tradeType === 'SELL'" class="payment-voucher">
                            <div v-if="!orderDetail.paymentVoucher">暂无</div>
                            <div class="read-voucher" v-else @click="showVoucher">查看</div>
                        </div> -->
                        <div v-if="orderDetail.status <= 2 && !expired" class="order-label">
                            <span v-if="isBuy">{{ $t('c2c.detail_order_info2') }}</span>
                            <span v-if="!isBuy">{{ $t('c2c.detail_order_info3') }}</span>
                        </div>
                        <div class="button-box">
                            <div>
                                <span @click="getPayOrder(orderDetail.orderId)" v-if="orderDetail.status <= 1 && !expired && isBuy" class="active">{{
                                    $t('c2c.detail_status_btn1')
                                }}</span>
                                <span
                                    @click="getAppealOrder(orderDetail.orderId)"
                                    v-if="showAppeal"
                                    class="active"
                                    :class="{ 'disabled': orderDetail === 5 }"
                                    >{{ $t('c2c.detail_status_btn5') }}</span
                                >
                                <span v-if="orderDetail.status === 5" class="disabled">{{ $t('c2c.detail_status_btn2') }}</span>
                                <span @click="getCancelOrder(orderDetail.orderId)" class="calcel-button" v-if="orderDetail.status === 1 && !expired && isBuy">{{
                                    $t('c2c.detail_status_btn3')
                                }}</span>
                            </div>
                            <div @click="getConfirmOrderPayment(orderDetail.orderId)" v-if="!isBuy && orderDetail.status === 2 && !expired">
                                <span class="active">{{ $t('c2c.detail_status_btn4') }}</span>
                            </div>
                        </div>
                        <!-- <div v-if="orderDetail.status === 4 && !isBuy" class="failed-order">
                            <i class="el-icon-error" />
                            <span>申诉驳回！并未真实付款</span>
                        </div> -->
                        <!-- <div v-if="orderDetail.status === 5 || expired" class="cancel-order">
                            <i class="el-icon-error" />
                            <span>订单已取消</span>
                        </div> -->
                        <div v-if="orderDetail.status === 6" class="success-order">
                            <div class="success-text">
                                <i class="el-icon-success" />
                                <span>{{ $t('c2c.detail_success') }}</span>
                            </div>
                            <span class="success-active">{{ $t('c2c.detail_balance') }}</span>
                        </div>
                    </div>
                    <div class="tips">
                        <div v-if="isBuy">
                            <h2>{{ $t('c2c.detail_desc1') }} :</h2>
                            <p>{{ $t('c2c.detail_desc2') }}</p>
                            <p>{{ $t('c2c.detail_desc3') }}</p>
                            <p>{{ $t('c2c.detail_desc4') }}</p>
                            <p>{{ $t('c2c.detail_desc5') }}</p>
                        </div>
                        <div v-if="!isBuy">
                            <h2>{{ $t('c2c.detail_desc1') }} :</h2>
                            <p v-if="!expired">>{{ $t('c2c.detail_desc6', { 'timeout': sysconfig.c2c.paymentTimeout }) }}</p>
                            <p>{{ $t('c2c.detail_desc1') }}</p>
                        </div>
                    </div>
                </div>
            </div>
        </div>
        <PaymentVoucher ref="paymentVoucher" :orderNumber="orderDetail.orderNumber" />
    </div>
</template>

<script>
// import Nav from './Nav.vue'
import PaymentVoucher from './PaymentVoucher.vue'
export default {
    components: {
        /* Nav, */
        PaymentVoucher
    },

    data() {
        return {
            orderDetail: {},
            orderExpireTime: '', // 倒计时
            loading: false,
            accountData: {},
            isMerchant: false,
            isBuy: false,
            expired: false, //是否过期
            interval: null
            /* res: {
                orderId: '192308290003000001',
                userId: 10602,
                adsId: 1006,
                sellOrder: false,
                currency: 'USDT',
                exchangeRate: 10,
                fiatSymbol: 'USD',
                status: 1,
                merchantId: 10606,
                merchantName: 'test-merchant',
                amount: 5,
                refCode: 1049422964952,
                paymentType: 'bank',
                accountData: '{"key":"bank","accountName":"daxiaomang","accountNo":"633333","bankName":"银行名称","branch":"开户支行"}',
                elapsedMS: 643627,
                timeCreated: 1693342714000,
                timeAccepted: 0,
                timePaid: 0,
                timeConfirmed: 0,
                timeUpdated: 1693342714000,
                timeoutMS: 9790
            } */
        }
    },

    created() {
        let id = this.$route.query.orderId
        let type = this.$route.query.orderType
        if (type && type === 'merchant') {
            this.isMerchant = true
        }
        this.getDetail(id)
    },

    watch: {},

    computed: {
        showAppeal() {
            let expiredStatusList = [1, 2]
            if (expiredStatusList.includes(this.orderDetail.status)) {
                return this.orderDetail.timeoutMS < 2592000000 && this.expired
            }
            let statusList = [3, 4]
            return statusList.includes(this.orderDetail.status) && this.orderDetail.timeoutMS < 2592000000
        },
        getStatusName() {
            switch (this.orderDetail.status) {
                case 5:
                    return this.$t('c2c.order_status5')
                case 4:
                    return this.$t('c2c.order_status4')
            }
            return this.$t('c2c.detail_msg4')
        }
    },

    methods: {
        // 获取订单详情
        getDetail(id) {
            this.loading = true
            this.expired = false
            let res
            res = this.isMerchant ? this.getMerchantOrderDetail(id) : this.getOrderDetail(id)
            if( res.errorcode === 0){
                this.loading = false
                this.orderDetail = res
                // 对用户来说，sellOrder为true 的单是买，否则为卖
                // 对商户来说，sellOrder为false 的单是买，否则为卖
                if (this.isMerchant) {
                    if (this.orderDetail.sellOrder) {
                        this.isBuy = false
                    } else {
                        this.isBuy = true
                    }
                } else {
                    if (this.orderDetail.sellOrder) {
                        this.isBuy = true
                    } else {
                        this.isBuy = false
                    }
                }
                if (res.accountData) {
                    this.accountData = JSON.parse(res.accountData)
                }
                this.getOrderExpireTime()
            } else {
                this.loading = false
                console.log(err)
            }
        },
        async getOrderDetail(orderId){
            return await $.callGetApi(this, `/c2c/order?id=${orderId}`)
        },
        async getMerchantOrderDetail(orderId){
            return await $.callGetApi(this, `/c2c/merchant/order?id=${orderId}`)
        },
        // 计算倒计时
        getOrderExpireTime() {
            // 超时时间大于30天则为无超时
            let time = this.orderDetail.timeoutMS
            if (time === 0) {
                this.expired = true
            } else {
                // 超过一个月的过期时间视为不过期
                if (time >= 2592000000) {
                    this.expired = false
                    if (this.interval) {
                        clearInterval(this.interval)
                    }
                } else {
                    this.examineInterval(time)
                }
            }
        },
        examineInterval(time) {
            if (this.interval) {
                clearInterval(this.interval)
            }
            this.orderExpireTime = ''
            this.interval = setInterval(() => {
                time -= 1000
                let min = parseInt(time / 60000)
                let second = parseInt((time % 60000) / 1000)
                if (min <= 0 && second <= 0) {
                    clearInterval(this.interval)
                    this.expired = true
                    this.getDetail(this.$route.query.orderId)
                    this.orderExpireTime = ''
                }
                if (min < 10) {
                    min = '0' + min
                }
                if (second < 10) {
                    second = '0' + second
                }
                this.orderExpireTime = min + ':' + second
            }, 1000)
        },
        // 复制订单号
        copyOrderNumber() {
            if (navigator.clipboard) {
                navigator.clipboard.writeText(this.orderDetail.orderId)
                this.$message({
                    message: this.$t('general.copied'),
                    type: 'success'
                })
            }
        },
        // 支付完成上传凭证
        /* payEnd() {
            this.$refs.paymentVoucher.dialogVisible = true
        }, */
        // 申诉
        getAppealOrder(id) {
            let res
            res = this.isMerchant ? this.appealMerchantOrder(id) : this.appealOrder(id)
            if(res.errorcode === 0){
                this.$message({
                        message: this.$t('c2c.detail_msg1'),
                        type: 'success'
                    })
                    this.orderDetail = res.data
            }
        },
        async appealOrder(orderId){
            return await $.callPostApi(this, `/c2c/order/appeal?id=${orderId}`)
        },
        async appealMerchantOrder(orderId){
            return await $.callPostApi(this, `/c2c/merchant/order/appeal?id=${orderId}`)
        },
        // 确认收款放币
        getConfirmOrderPayment(id) {
            let res
            res = this.isMerchant ? this.confirmMerchantOrderPayment(id) : this.confirmOrderPayment(id)
            if(res.errorcode === 0){
                this.$message({
                    message: this.$t('c2c.detail_msg2'),
                    type: 'success'
                })
                this.orderDetail = res
            }
        },
        async confirmOrderPayment(orderId){
            return await $.callPostApi(this, `/c2c/order/confirm?id=${orderId}`)
        },
        async confirmMerchantOrderPayment(orderId){
            return await $.callPostApi(this, `/c2c/merchant/order/confirm?id=${orderId}`)
        },
        // 确认付款
        getPayOrder(id) {
            let res
            res = this.isMerchant ? this.payMerchantOrder(id) : this.payOrder(id)
            if(res.errorcode === 0){
                this.$message({
                        message: this.$t('c2c.detail_msg3'),
                        type: 'success'
                    })
                    this.orderDetail = res.data
                    if (this.interval) {
                        clearInterval(this.interval)
                    }
                    this.getOrderExpireTime()
            }
        },
        async payOrder(orderId){
            return await $.callPostApi(this, `/c2c/order/paid?id=${orderId}`)
        },
        async payMerchantOrder(orderId){
            return await $.callPostApi(this, `/c2c/merchant/order/paid?id=${orderId}`)
        },
        // 取消订单
        getCancelOrder(id) {
            let res
            res = this.isMerchant ? this.cancelMerchantOrder(id) : this.cancelOrder(id)
            if(res.errorcode === 0){
                this.$message({
                        message: this.$t('c2c.order_status4'),
                        type: 'success'
                    })
                    if (this.interval) {
                        clearInterval(this.interval)
                    }
                    this.orderDetail = res.data
            }
        },
        async cancelOrder(orderId){
            return await $.callPostApi(this, `/c2c/order/cancel?id=${orderId}`)
        },
        async cancelMerchantOrder(orderId){
            return await $.callPostApi(this, `/c2c/merchant/order/cancel?id=${orderId}`)
        },
        // 展示支付凭证
        showVoucher() {}
    }
}
</script>
<style lang="less" scoped>
.container {
    width: -webkit-fill-available;
    padding: 39px 0;
}
.v4-trade {
    color: #000922;
    background-color: #fafafa;
}
.order-header {
    display: flex;
    padding: 24px 28px;
    justify-content: space-between;
    .status-text {
        font-size: 20px;
        margin-bottom: 4px;
        line-height: 27px;
        font-weight: 500;
    }
    .status-tips,
    .order-top-info {
        line-height: 24px;
    }
    .status-tips {
        display: flex;
    }
    .order-header-right {
        text-align: right;
    }
    .el-icon-warning {
        color: #848e9c;
    }
    .el-icon-time {
        font-weight: bold;
        font-size: 18px;
    }
    .label {
        color: #707a8a;
    }
}
.step-info {
    padding: 28px;
    display: flex;
    .step-left-info {
        padding: 0 29px;
        flex-grow: 1;
        .order-label {
            margin-bottom: 12px;
            font-size: 16px;
        }
        .step-order-info {
            display: flex;
            font-size: 18px;
            padding-bottom: 32px;
            .step-label {
                margin-bottom: 14px;
                margin-top: 14px;
                font-size: 14px;
                color: #707a8a;
            }
            .total-price {
                color: #0ecb81;
            }
            > div {
                min-width: 190px;
            }
        }
        .pay-methods {
            display: flex;
            border: 1px solid rgba(24, 26, 32, 0.04);
            margin-bottom: 32px;
            padding: 28px;
            .method-name {
                padding: 8px;
                margin: 16px;
                width: 300px;
                height: 16px;
                background-color: #f5f5f5;
            }
            .method-info {
                padding: 28px;
                flex-grow: 1;
                border-left: 1px solid rgba(24, 26, 32, 0.04);
            }
            .name-text {
                padding-left: 8px;
                border-left: 3px solid #fafafa;
            }
            .wechat {
                border-color: #2dc100;
            }
            .aliPay {
                border-color: #01a9f2;
            }
            .bank {
                border-color: #f0b90b;
            }
            .method-label {
                margin-bottom: 8px;
            }
            .method-text {
                margin-bottom: 16px;
            }
        }
        .payment-voucher {
            margin-bottom: 32px;
            > div {
                height: 50px;
                display: flex;
                justify-content: center;
                align-items: center;
            }
            border: 1px dashed rgba(24, 26, 32, 0.1);
            .read-voucher {
                background-color: rgb(234, 236, 239);
                color: #d0980b;
                cursor: pointer;
            }
        }
        .button-box {
            margin: 24px 0;
            span {
                padding: 6px 12px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px;
                width: 126px;
                box-sizing: border-box;
                display: inline-block;
                text-align: center;
                cursor: pointer;
            }
            .active {
                background-color: #f0b90b;
                color: #fff;
            }
            .disabled {
                background-color: rgb(234, 236, 239);
                color: #000922;
                cursor: no-drop;
            }
            .calcel-button {
                color: #f0b90b;
            }
        }
        .failed-order,
        .cancel-order {
            min-height: 220px;
            display: flex;
            justify-content: center;
            align-items: center;
            i {
                font-size: 40px;
            }
        }
        .failed-order {
            color: #ef3123;
        }
        .cancel-order {
            color: #707a8a;
        }
        .success-order {
            min-height: 220px;
            color: #2dc100;
            text-align: center;
            .success-text {
                display: flex;
                justify-content: center;
                align-items: center;
                margin-bottom: 36px;
                i {
                    font-size: 40px;
                }
            }
            .success-active {
                padding: 6px 12px;
                line-height: 20px;
                font-size: 14px;
                font-weight: 500;
                border-radius: 4px;
                width: 126px;
                box-sizing: border-box;
                display: inline-block;
                text-align: center;
                cursor: pointer;
                background-color: #f0b90b;
                color: #fff;
            }
        }
    }
    .tips {
        width: 35%;
        border-left: 1px solid rgba(24, 26, 32, 0.04);
        padding: 0 28px;
        h2 {
            font-weight: bold;
            font-size: 16px;
        }
        p {
            margin-top: 20px;
            line-height: 1.5;
            color: #707a8a;
        }
    }
}
.el-icon-copy-document {
    cursor: pointer;
}
/deep/.el-timeline-item__timestamp,
/deep/.el-step__title.is-wait {
    color: #000922;
}
@media (max-width: 1024px) {
    .step-info {
        display: block;
        .tips {
            width: auto;
            border-left: none;
        }
    }
}
@media (max-width: 768px) {
    .step-info {
        display: block;
        .tips {
            width: auto;
            border-left: none;
        }
    }
    .order-header {
        display: block;
        .order-top-info {
            text-align: left;
        }
    }
    /deep/.el-steps {
        justify-content: center;
    }
}
</style>
