<template>
    <div class="row">
        <h4 class="banlance-list-title d-none d-md-block">{{ $t('coin_treasure.holding_assets') }}</h4>
        <div class="col balance-list">
            <div class="total">
                <div class="actions"></div>
            </div>

            <dl class="title-row d-none d-md-block">
                <dt>
                    <div class="hidden-sm">{{ $t('assets.account_type') }}</div>
                    <div class="hidden-sm">{{ $t('assets.balance') }}</div>
                    <div class="hidden-sm">{{ $t('assets.available_balance') }}</div>
                    <div class="hidden-sm">{{ $t('assets.frozen') }}</div>
                    <div class="hidden-sm"><!-- deposit/withdraw actions --></div>
                </dt>
            </dl>

            <loading-indicator v-if="!balance_list" />

            <dl v-else v-for="balance in balance_list" class="currency-row" :key="balance.currency">
                <dd class="dis-pc">
                    <div>{{ getTokenName(balance) }}</div>
                    <div>{{ balance.balance }}</div>
                    <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                    <div v-else>{{ balance.available }}</div>
                    <div>{{ balance.frozen }}</div>

                    <div class="actions">
                        <template v-if="isDepositSupported(balance.currency)">
                            <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                {{ $t('general.deposit') }}
                            </a>
                            <div class="column-line"></div>
                            <router-link
                                :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                    $t('general.withdraw') }}</router-link>
                        </template>
                        <template v-else-if="balance.currency !== 'FTUSDT'">
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                            <div class="column-line"></div>
                            <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                        </template>
                    </div>
                </dd>
                <dd class="dis-mb" @click="toggleShowActions(balance.currency)">
                    <div id="list-main">
                        <div>{{ getTokenName(balance) }}</div>
                        <div class="total-assets">
                            <div>{{ balance.balance }}</div>
                            <svg viewBox="0 0 256 256" class="arrow-right">
                                <use xlink:href="/dist/svg/icons.svg#icon-arrow-right" />
                            </svg>
                        </div>
                    </div>
                    <div v-if="showActionsMap[balance.currency]">
                        <div id="list-balance">
                            <div>
                                <div>{{ $t('assets.available_balance') }}</div>
                                <div>{{ $t('assets.frozen') }}</div>
                            </div>
                            <div>
                                <div v-if="balance.currency === 'FTUSDT'">{{ futures_assets }}</div>
                                <div v-else>{{ balance.available }}</div>
                                <div>{{ balance.frozen }}</div>
                            </div>
                        </div>
                        <div class="actions">
                            <template v-if="isDepositSupported(balance.currency)">
                                <a href="javascript:;" class="link-deposit" @click="toggleDeposit(balance.currency)">
                                    {{ $t('general.deposit') }}
                                </a>
                                <div class="column-line"></div>
                                <router-link
                                    :to="'/finance/withdraw/' + encodeURIComponent(balance.currency.toLowerCase())">{{
                                        $t('general.withdraw') }}</router-link>
                            </template>
                            <template v-else-if="balance.currency !== 'FTUSDT'">
                                <a href="javascript:;" class="disabled" disabled>{{ $t('general.deposit') }}</a>
                                <div class="column-line"></div>
                                <a href="javascript:;" class="disabled" disabled>{{ $t('general.withdraw') }}</a>
                            </template>
                        </div>
                    </div>
                </dd>
                <!-- display deposit address -->
                <div v-if="isDepositSupported(balance.currency) && deposit_currency === balance.currency"
                    class="deposit-address-block">
                    <deposit-form :bcConfig="bcConfig" :coinConfig="getCoinConfig(balance.currency)" />
                </div>
            </dl>

        </div>
    </div>
</template>

<script>
import DepositForm from '../../Finance/Components/DepositForm.vue';
import '@/assets/images/eu1/show-actions.svg'
export default {
    components: { DepositForm },
    props: ['balance_list', 'futures_assets', 'bcConfig'],

    data() {
        return {
            coinMap: {},

            // The active currency for deposits
            deposit_currency: '',
            //showMoney-showActions
            showActionsMap: {},
            showMoneyMap: {},
            isRotatedMap: {}
        }
    },

    created() {
        // Build coin map
        const coins = (this.bcConfig ? this.bcConfig.supportedCoins : null) || [];
        if (!coins.length === 0)
            throw new Error('Tokens are not properly configured.');

        const map = {};
        $(coins).each((index, item) => {
            map[item.currency.toUpperCase()] = Object.freeze(item);
        });
        this.coinMap = Object.freeze(map);
        //Initialize the corresponding switch state for each balance.
       this.balance_list && this.balance_list.forEach(balance => {
            this.$set(this.showMoneyMap, balance.currency, false);
            this.$set(this.showActionsMap, balance.currency, false);
            this.$set(this.isRotatedMap, balance.currency, false);
        });
    },

    methods: {
        /**
         * Determines whether supports deposits for the specified coin or not.
         * @param {string} coin The name of the coin.
         */
        isDepositSupported: function (coin) {
            return !!this.coinMap[coin];
        },

        getTokenName: function (token) {
            switch (token.currency) {
                case 'FTUSDT':
                    return this.$t('assets.label_futures_account');
                case 'INCT':
                    return this.$t('assets.label_commission');
                default:
                    return token.display_name;
            }
        },

        toggleDeposit: function (currency) {
            this.deposit_currency = this.deposit_currency === currency ? null : currency;
        },

        getCoinConfig: function (coin) {
            return this.coinMap[coin.toUpperCase()];
        },
        toggleShowMoney(currency) {
            this.$set(this.showMoneyMap, currency, !this.showMoneyMap[currency]);
            this.$set(this.isRotatedMap, currency, !this.isRotatedMap[currency]);
        },
        toggleShowActions(currency) {
            this.$set(this.showActionsMap, currency, !this.showActionsMap[currency]);
            this.$set(this.isRotatedMap, currency, !this.isRotatedMap[currency]);
        },
    }
}
</script>
<style scoped>
.banlance-list-title {
    font-weight: normal;
    font-size: 24px;
    color: #000000;
    line-height: 34px;
    margin: 40px 0;
}
.balance-list {
    background-color: #fff;
    border-radius: 40px;
    padding: 30px 70px;
}
.actions a {
    min-width: 58px;
    text-align: center;
}

.actions .link-deposit {
    color: #000;
    min-width: 58px;
    text-align: center;
    margin-right: 10px;
    padding: 3px 6px 3px 6px;
}

.actions a:nth-child(3) {
    margin-left: 10px;
    color: #000;
    padding: 3px 6px 3px 6px;
}

.actions a:hover {
    cursor: pointer;
    color: #11CF8B;
}

.currency-row .actions .disabled {
    margin-right: 10px;
    color: #A8ABAE;
    padding: 3px 6px 3px 6px;
}

.currency-row .actions a:nth-child(3) {
    margin-right: 0;
    padding: 3px 6px 3px 6px;
}

.actions .disabled:hover {
    cursor: default;
}

.balance-list .deposit-address-block {
    border: 1px solid #EDEEEF;
}

.actions {
    display: flex;
    justify-content: flex-end;
    flex-wrap: nowrap;
    margin-top: 10px;
}

.column-line {
    border-right: 1px solid #000;
}

.dis-pc {
    display: flex;
}

.dis-mb {
    display: none;
}

.total-assets {
    display: flex;
    align-items: center;
}

.eyes {
    display: none;
    width: 14px;
    height: 9px;
    margin-left: 10px;
}

.rotate-img {
    transform: rotate(90deg);
}
.arrow-right {
    width: 13px;
    margin-left: 20px;
}

@media (max-width: 1024px) {
    .d-none {
        display: none !important;
    }
    .dis-pc {
        display: none;
    }

    .dis-mb {
        display: flex;
        flex-direction: column;
        align-items: stretch;
    }

    #list-main {
        display: flex;
        justify-content: space-between;
    }

    #list-balance {
        display: flex;
        justify-content: space-between;
        background: #11CF8B;
        border-radius: 0px 0px 0px 0px;
        padding: 10px;
        margin-top: 10px;
        color: #fff;
        border-radius: 8px;
    }
    #list-balance>div {
        line-height: 25px;
    }
    .balance-list {
        border-radius: 10px;
        padding: 0 32px;
    }
}
</style>