<template>
    <nav aria-label="navigation" class="mt-3" v-if="pages.length > 1">
        <ul class="pagination">
            <li class="page-item">
                <a class="page-link" href="javascript:;" aria-label="Previous" @click="onPage($event, 0)">&laquo;</a>
            </li>
            <li class="page-item" v-for="page in pages" :key="page" :class="{ disabled: current_page === page }">
                <a class="page-link" href="javascript:;" @click="onPage($event, page)">{{ page + 1 }}</a>
            </li>
            <li class="page-item">
                <a class="page-link" href="javascript:;" aria-label="Next" @click="onPage($event, max_page_index)">&raquo;</a>
            </li>
        </ul>
    </nav>
</template>

<style scoped>
.page-link {
    color: #979797;
    border-color: #f6f6f6;
}
.pagination .disabled{
    color: rgba(0,0,0,0.9);
    font-weight: 600;
}
</style>

<script>
export default {
    props: ['total', 'page_size', 'page_index'],

    data() {
        return {
            pages: [],
            current_page: 0,
            max_page_index: 0
        };
    },

    mounted() {
        this.resetPages();
    },

    watch: {
        /**
         * reset pages when either 'total' or 'page_size' gets changed.
         */
        total: function () {
            this.current_page = 0;
            this.resetPages();
        },
        page_size: function () {
            this.current_page = 0;
            this.resetPages();
        },
        page_index: function () {
            this.current_page = this.page_index * 1;
            this.resetPages();
        }
    },

    methods: {
        resetPages: function () {
            let pageSize = this.page_size * 1;
            if (isNaN(pageSize)) {
                pageSize = 2;
            }

            let total = this.total * 1;
            if (isNaN(total)) {
                total = 0;
            }

            let maxPageIndex = Math.ceil(total / pageSize) - 1;
            let startPageIndex = Math.max(0, this.current_page - 3);
            let endPageIndex = Math.min(this.current_page + 3, maxPageIndex);

            endPageIndex = Math.min(maxPageIndex, startPageIndex + 6);
            if (maxPageIndex - 6 < startPageIndex)
                startPageIndex = Math.max(0, maxPageIndex - 6);

            let output = [];
            for (let i = startPageIndex; i <= endPageIndex; i++) {
                output.push(i);
            }

            this.max_page_index = maxPageIndex;
            this.pages = Object.freeze(output);
        },

        onPage: function (event, page) {
            event.target.blur();

            console.log(`## Change page index to ${page}`);
            this.$emit('page-changed', page);
        }
    }
};
</script>