<!-- 发布广告 -->
<template>
    <section class="page">
        <div class="top-link"></div>
        <div class="c2c-payment-trade-ad">
            <div class="trade-ad-header">
                <div class="title">{{ adDetail ? $t('c2c.change_ad') : $t('c2c.trade_ad') }}</div>
            </div>
            <div class="trade-ad-form">
                <div class="form-box">
                    <div class="form">
                        <div class="trade-ad-tip">
                            <el-alert :title="$t('c2c.trade_alert_title')" :closable="false" type="warning" show-icon>
                                <div class="description" v-html="$t('c2c.trade_alert_desc')"></div>
                            </el-alert>
                        </div>

                        <el-form :model="ruleForm" :rules="rules" ref="ruleForm" label-width="auto" label-position="top">
                            <el-form-item :label="$t('c2c.trade_currency')" prop="currency" required>
                                <el-select
                                    :disabled="adDetail !== null"
                                    v-model="ruleForm.currency"
                                    :placeholder="$t('c2c.trade_currency_ph')"
                                    @change="getBalanceData"
                                >
                                    <el-option v-for="(symbol, key) in currencySymbols" :key="key" :label="symbol" :value="symbol"></el-option>
                                </el-select>
                            </el-form-item>

                            <el-form-item :label="$t('c2c.trade_sellOrder')" required>
                                <el-radio-group :disabled="adDetail !== null" v-model="ruleForm.sellOrder">
                                    <el-radio v-model="ruleForm.sellOrder" :label="false">{{ $t('c2c.buy') }}</el-radio>
                                    <!-- <el-radio v-model="ruleForm.sellOrder" :label="true">{{ $t('c2c.sell') }}</el-radio> -->
                                </el-radio-group>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.price') + ' :'" prop="exchangeRate" required>
                                <el-input v-model="ruleForm.exchangeRate"></el-input>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.amount') + ' :'" prop="totalAmount" required>
                                <el-input :disabled="adDetail !== null" v-model="ruleForm.totalAmount"></el-input>
                                <div class="bottom-tip">{{ $t('c2c.trade_amount_tip') + ' :' }}{{ balance }} {{ ruleForm.currency }}</div>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.trade_minamount') + ' :'" prop="minAmount" required>
                                <el-input v-model="ruleForm.minAmount"></el-input>
                                <div class="bottom-tip">{{ $t('c2c.trade_minamount') }}{{ ruleForm.minAmount }} {{ ruleForm.currency }}</div>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.trade_maxamount') + ' :'" prop="maxAmount" required>
                                <el-input v-model="ruleForm.maxAmount"></el-input>
                                <div class="bottom-tip">{{ $t('c2c.trade_maxamount') }}{{ ruleForm.maxAmount }} {{ ruleForm.currency }}</div>
                            </el-form-item>
                            <el-form-item :label="$t('c2c.fiatSymbol') + ' :'" prop="fiatSymbol" required>
                                <el-select :disabled="adDetail !== null" v-model="ruleForm.fiatSymbol" :placeholder="$t('c2c.trade_fiatSymbol_ph')">
                                    <el-option v-for="(symbol, key) in fiatSymbols" :key="key" :label="symbol" :value="symbol"></el-option>
                                </el-select>
                            </el-form-item>
                            <el-form-item v-if="ruleForm.resource === '1'" :label="$t('c2c.trade_pwd') + ' :'" prop="password" required>
                                <el-input :placeholder="$t('c2c.trade_pwd_ph')" v-model="ruleForm.password" show-password></el-input>
                            </el-form-item>

                            <el-form-item>
                                <el-button v-if="adDetail === null" type="primary" :disabled="disabled" :loading="submiting" @click="submitForm('ruleForm')">{{
                                    $t('c2c.trade_ad')
                                }}</el-button>
                                <el-button v-else type="primary" :disabled="disabled" :loading="submiting" @click="updateAd('ruleForm')">{{
                                    $t('c2c.change_ad')
                                }}</el-button>
                                <!-- <el-button @click="resetForm('ruleForm')">重置</el-button> -->
                            </el-form-item>
                        </el-form>
                    </div>
                </div>
            </div>
        </div>
    </section>
</template>

<script>

export default {
    data() {
        return {
            balance: 0,
            fiatSymbols: [],
            currencySymbols: [],
            submiting: false,
            disabled: false,
            adDetail: null,
            balanceData: [], // 账户各币种金额数据
            ruleForm: {
                sellOrder: false,
                currency: 'USDT',
                fiatSymbol: 'USD',
                exchangeRate: '',
                totalAmount: '',
                minAmount: '',
                maxAmount: ''
            },
            rules: {
                exchangeRate: [{ required: true, message: this.$t('c2c.trade_error1'), trigger: 'blur' }],
                totalAmount: [{ required: true, message: this.$t('c2c.trade_error2'), trigger: 'blur' }],
                minAmount: [{ required: true, message: this.$t('c2c.trade_error3'), trigger: 'blur' }],
                maxAmount: [{ required: true, message: this.$t('c2c.trade_error4'), trigger: 'blur' }]
            }
        }
    },
    created() {
        if (this.$route.query && this.$route.query.id) {
            this.adDetail = this.$route.query
            this.ruleForm = { ...this.$route.query }
        }
        // console.log(this.sysconfig.c2c)
        if (this.sysconfig.c2c && this.sysconfig.c2c.fiatSymbols.length > 0) {
            this.fiatSymbols = this.sysconfig.c2c.fiatSymbols
            this.ruleForm.fiatSymbol = this.sysconfig.c2c.fiatSymbols[0]
        }
        if (this.sysconfig.c2c && this.sysconfig.c2c.tokens.length > 0) {
            this.currencySymbols = this.sysconfig.c2c.tokens
            this.ruleForm.currency = this.sysconfig.c2c.tokens[0]
        }
        this.getBalance()
    },
    mounted() {},
    methods: {
        getBalance() {
            $.callPostApi(this, '/api/v1/balance/list').then((res) => {
                this.balanceData = res
                this.getBalanceData()
            })
        },
        // 更新对应币种账户余额数据
        getBalanceData() {
            let data
            data = this.balanceData.find((item) => {
                return item.currency === this.ruleForm.currency
            })
            if (data) {
                this.balance = data.balance + data.frozen
            } else {
                this.balance = 0
            }
        },
        updateAd(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    this.submiting = true
                    let data = {
                        id: this.ruleForm.id,
                        status: this.ruleForm.status,
                        exchangeRate: this.ruleForm.exchangeRate,
                        minAmount: this.ruleForm.minAmount,
                        maxAmount: this.ruleForm.maxAmount
                    }
                    $.callPostApi(this, '/api/v1/c2c/merchant/updateads', data).then((res) => {
                        if(res.errcode === 0){
                            this.submiting = false
                            this.disabled = true
                            this.$message({ message: this.$t('c2c.trade_change_success'), type: 'success' })
                            setTimeout(() => {
                                this.$router.push('/myAd')
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.submiting = false
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        submitForm(formName) {
            this.$refs[formName].validate((valid) => {
                if (valid) {
                    // alert('submit!')
                    this.submiting = true
                    $.callPostApi(this, '/api/v1/c2c/merchant/ads', this.ruleForm).then((res) => {
                        if(res.errcode === 0){
                            this.submiting = false
                            this.disabled = true
                            this.$message({ message: this.$t('c2c.trade_create_success'), type: 'success' })
                            setTimeout(() => {
                                this.$router.push('/myAd')
                            }, 2000)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                        this.submiting = false
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        },
        resetForm(formName) {
            this.$refs[formName].resetFields()
        }
    }
}
</script>
<style lang="less" scoped>
.c2c-payment-trade-ad{
    width: -webkit-fill-available;
    width: -moz-available;
}
.trade-ad-header {
    box-shadow: rgba(0, 0, 0, 0.08) 0px 2px 4px;
    background: #fff;
    position: relative;
    .title {
        padding-left: 16px;
        margin: 0px auto;
        max-width: 1136px;
        font-size: 24px;
        padding: 20px 0px;
        font-weight: 600;
        text-indent: 16px;
    }
}
.trade-ad-form {
    background-color: #f9f9fa;
    padding: 16px;
    .trade-ad-tip {
        margin-bottom: 24px;
        /deep/.el-alert--warning.is-light {
            align-items: flex-start;
        }
        /deep/.el-alert__icon.is-big {
            font-size: 20px;
            margin-top: 22px;
        }
        .description {
            line-height: 20px;
            font-size: 12px;
            line-height: 1.33;
            color: #1e2329;
            padding-bottom: 5px;
        }
    }
    .el-alert__icon.is-big {
        font-size: 14px;
    }
    .type-title {
        margin-bottom: 24px;
        .title {
            font-weight: 500;
            font-size: 20px;
            line-height: 28px;
            white-space: nowrap;
            overflow: hidden;
            text-overflow: ellipsis;
            .line {
                display: inline-block;
                margin: 0px 12px 0px 0px;
                width: 4px;
                height: 14px;
                border-radius: 4px;
                background-color: #f0b90b;
            }
        }
    }
    .form {
        max-width: 512px;
        margin: 0px auto;
        width: 100%;
    }
    /deep/.el-input.is-active .el-input__inner,
    /deep/.el-select .el-input.is-focus .el-input__inner,
    /deep/.el-input__inner:focus {
        border-color: #f0b90b;
    }

    /deep/.el-form--label-top .el-form-item__label {
        padding: 0;
        // height: 20px;
        line-height: 20px;
        margin-bottom: 6px;
        padding: 0;
    }
    .form-btn {
    }
}
.form-box {
    box-sizing: border-box;
    margin: 0px;
    min-width: 0px;
    box-shadow: rgba(20, 21, 26, 0.04) 0px 1px 2px, rgb(71 77 87 / 4%) 0px 3px 6px, rgba(20, 21, 26, 0.1) 0px 0px 1px;
    border-radius: 4px;
    background-color: #ffffff;
    padding: 40px 24px;
}
@media screen and (min-width: 767px) {
    .trade-ad-form {
        padding: 16px 24px;
    }
}
@media screen and (max-width: 767px) {
    .form-box {
        padding-top: 20px;
    }
}
@media screen and (min-width: 1023px) {
    .trade-ad-form {
        padding: 16px 32px;
    }
}
</style>
