<template>
  <section class="page login-page layout-login d-flex">
    <div class="d-flex flex-row login-left-right flex-fill">
      <div class="login-page-right login-container">
          <div class="img-box-md d-block d-md-none">
            <img
                class="img-award-md"
                src="../../assets/images/de-twaa/phone_img@2x.png"
                alt=""
              />
          </div>
          <div class="login-box">
            <div class="login container">
              <div class="login-inner" id="login-page">
                <div class="row">
                  <div class="col">
                      <h1 class="login-title">{{ $t("login.title") }}</h1>
                  </div>
                </div>
                <login-form-component
                  v-if="regions"
                  v-bind:regions="regions"
                  v-bind:csrfToken="csrfToken"
                />
                <loading-indicator v-else />
              </div>
            </div>
          </div>
          <div class="img">
              <img
                class="img-award"
                src="../../assets/images/de-twaa/page_img@2x.png"
                alt=""
              />
          </div>
      </div>
    </div>
  </section>
</template>

<style scoped lang="less">
.sysconfig-name {
  display: flex;
  align-items: center;
  margin-bottom: 33px;
  height: 25px;
  img {
    width: 22px;
    height: auto;
  }
  h2 {
    font-family: Plus Jakarta Sans, Plus Jakarta Sans;
    font-weight: 500;
    font-size: 21px;
    color: #0E0637;
    margin-bottom: 0;
  }
}
.login-title {
  padding: 0;
  font-family: PingFang HK, PingFang HK;
  font-weight: 500;
  font-size: 30px;
  color: #000000;
  line-height: 56px;
  padding-top: 6px;
}
.login-des {
  margin-top: 8px;
  font-family: PingFang HK, PingFang HK;
  font-weight: 400;
  font-size: 17px;
  color: #717184;
  line-height: 28px;
  margin-bottom: 40px;
}
.login-left-right {
  margin: auto;
  width: 100%;
}
.login-container {
  background: #fff;
  display: flex;
  height: 100%;
  align-items: center;
}
.img {
  padding-right: 10%;
  height: 100%;
  padding-right: 0;
  display: flex;
  justify-content: left;
  background-color: #0E0637;
}
.img 
.login-page .login h1 {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 26px;
  color: #3d3d3d;
  text-align: start;
  padding-top: 0;
  padding-bottom: 4rem;
}
.login-container > div img {
  width: auto;
  height: auto;
  margin: auto;
  max-width: 100%;
  max-height: 100%;
}
.img,
.login-box {
  width: 50%;
  height: 100%;
  flex: 1;
}
.img {
  text-align: center;
}
.login-box {
  padding-left: 10%;
}
.right-content {
  background: #f8f9ff;
  position: relative;
  width: 320px;
  min-height: 550px;
  display: inline-block;
  margin-top: 200px;
  padding: 15px;
  margin-bottom: 100px;
}
.right-title {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 24px;
  color: #3d3d3d;
  border-bottom: 1px solid #d8d8d8;
  min-height: 40px;
  margin-top: 110px;
}
.right-boby {
  margin-top: 10px;
}
.right-boby > div {
  text-align: left;
  padding: 2px 10px;
}
.right-boby > div:nth-child(2) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 600;
  font-size: 16px;
  color: #2c2236;
}
.right-boby > div:nth-child(3) {
  font-family: PingFangSC, PingFang SC;
  font-weight: 500;
  font-size: 12px;
  color: #2c2236;
}
@media (max-width: 1024px) {
  .login-box {
    min-width: inherit;
    width: 100%;
  }
  .login-container {
    background: #fff;
  }

  .login-container .img {
    display: none;
  }


  .login-page .login h1 {
    color: #000;
  }

}
@media (max-width: 768px) {
  #login-page .row {
    display: none;
  }
  .img-box-md {
    width: 100%;
    margin-top: -20px;
    .img-award-md {
      width: 100%;
      height: auto;
    }
  }
  .login-container {
    display: block;
  }
  .login-box {
    padding-left: 0;
    margin-top: -380px;
    border-radius: 30px;
    position: relative;
    z-index: 9;
    .login {
      max-width: none;
      background-color: #fff;
      border-radius: 30px;
      width: 350px;
    }
  }
}
</style>

<script type="text/javascript">
import LoginFormComponent from "./Components/LoginForm.vue";
import { getAppContext } from "utilities/helper";

export default {
  components: { LoginFormComponent },
  data() {
    return { regions: null, csrfToken: null };
  },

  created() {
    // Already signed in?
    const context = getAppContext();
    const profile = context.profile;
    if (profile) {
      this.$router.push("/user");
    } else {
      this.initAsync();
    }
  },

  watch: {
    "$i18n.locale": function () {
      this.reloadRegionsAsync();
    }
  },

  methods: {
    initAsync: async function () {
      const self = this;

      // Read a list of supported regions.
      const resp = await $.callGetApi(self, "/api/v1/config/regions");

      // Read csrf token
      const token = await $.callPostApi(self, "/api/v1/csrftoken");
      if (token && typeof token === "string") {
        self.regions = resp.data;
        self.csrfToken = token;
      }
    },

    reloadRegionsAsync: async function () {
      // Read a list of supported regions.
      const resp = await $.callGetApi(self, "/api/v1/config/regions");
      if (resp && resp.data) {
        this.regions = resp.data;
      }
    }
  }
};
</script>