<template>
    <div class="container page" :class="{ 'transfer': isTransfer }">
        <div class="trade-box">
            <Nav />
            <div class="order-table">
                <div class="table-header">
                    <div class="user-info">{{ $t('c2c.merchant') }}</div>
                    <!-- <div class="quantity-limit">{{ $t('c2c.index_limit') }}</div> -->
                    <div class="price">{{ $t('c2c.price') }}</div>
                    <!-- <div class="trade-methods">{{ $t('c2c.pay_type') }}</div> -->
                    <div class="operate">{{ $t('c2c.order_operate') }}</div>
                </div>
                <div class="table-list" :class="{ 'shadow': actionTrade.id === data.id }" v-for="data in tableData" :key="data.MaxSingleTransAmount">
                    <div class="list" v-if="actionTrade.id !== data.id">
                        <div class="user-info">
                            <!-- <div class="avatar-name">
                                <Coin :fillData="fillData" :name="$version !== 'v11' ? data.merchantName : 'USDT'" />
                            </div> -->
                            <div class="avatar-info">
                                <div class="name">{{ data.merchantName }}</div>
                            </div>
                        </div>
                        <!-- <div class="quantity-limit">
                            <div class="amount">
                                <span class="lable">{{ $t('c2c.amount') }}</span>
                                {{ (data.totalAmount - data.completedAmount - data.frozenAmount).toFixed(4) }}
                            </div>
                            <div>
                                <span class="lable">{{ $t('c2c.index_limit2') }}</span>
                                {{ data.currency }}{{ data.minAmount }}
                                <span>-</span>
                                {{ data.currency }}{{ data.maxAmount }}
                            </div>
                        </div> -->
                        <div class="price">
                            <span class="pc-none">{{ $version === 'v11' ? $t('c2c.price_v11') : $t('c2c.price') }}</span
                            >{{ data.exchangeRate }}<span>{{ data.fiatSymbol }}</span>
                        </div>
                        <!-- <div class="trade-methods">
                            <span class="pc-none">{{ $t('c2c.pay_type') }}</span
                            ><span v-for="payType in data.paymentTypes" :key="payType">{{ getPaymentName(payType) }}</span>
                        </div> -->
                        <div class="operate">
                            <!-- 跳转传入用户名称，缺少参数 -->
                            <span v-if="active === 'buy'" class="buy-button" @click="openTelegramChat(data.contact)">{{ $t('c2c.buy') }} {{ activeCoin }}</span>
                            <!-- <span v-if="active === 'buy'" class="buy-button" @click="trade(data)">{{ $t('c2c.buy') }}{{ activeCoin }}</span> -->
                            <!-- <span v-if="active === 'sell'" class="sell-button" @click="trade(data)">{{ $t('c2c.sell') }}{{ activeCoin }}</span> -->
                        </div>
                    </div>
                    <div class="trade-page" v-if="actionTrade.id === data.id" v-loading="loading">
                        <div class="left-user-info">
                            <div class="trading-name">
                                <Coin :fillData="fillData" :name="data.merchantName" />
                                <div class="name">{{ data.merchantName }}</div>
                                <div class="turnover">
                                    {{ data.completedOrders }}
                                    <span>{{ $t('c2c.turnover') }}</span>
                                </div>
                                <span class="segmentation">|</span>
                                <div class="turnover">
                                    {{
                                        data.totalOrders === 0 || (data.completedOrders / data.totalOrders) * 100 === 0
                                            ? 0
                                            : ((data.completedOrders / data.totalOrders) * 100).toFixed(2)
                                    }}%
                                    <span>{{ $t('c2c.transaction_rate') }}</span>
                                </div>
                            </div>
                            <div class="trading-price">
                                <div>
                                    <span class="lable">{{ $t('c2c.price') }}</span>
                                    <span :class="{ 'sell-color': active === 'sell', 'buy-color': active === 'buy' }">{{ data.exchangeRate }}</span>
                                    <span :class="{ 'sell-color': active === 'sell', 'buy-color': active === 'buy' }">{{ data.fiatSymbol }}</span>
                                </div>
                                <div class="amount">
                                    <span class="lable">{{ $t('c2c.amount') }}</span>
                                    {{ data.totalAmount
                                    }}<!-- {{ data.asset }} -->
                                </div>
                            </div>
                            <div class="trading-methods">
                                <div>
                                    <span class="lable">{{ $t('c2c.payment_time_limit') }}</span>
                                    <span>{{ sysconfig.c2c.paymentTimeout }} {{ $t('c2c.minute') }}</span>
                                </div>
                                <div>
                                    <span v-if="active === 'sell'" class="lable">{{ $t('c2c.buyer_pay') }}</span>
                                    <span v-if="active === 'buy'" class="lable">{{ $t('c2c.seller_pay') }}</span>
                                    <span class="detailPayType" v-for="payType in data.paymentTypes" :key="payType">{{ getPaymentName(payType) }}</span>
                                </div>
                            </div>
                            <div class="trading-methods">
                                <span class="lable">{{ $t('c2c.index_limit2') }}</span>
                                {{ data.currency }}{{ data.minAmount }}
                                <span>-</span>
                                {{ data.currency }}{{ data.maxAmount }}
                            </div>
                        </div>
                        <div class="form-info">
                            <el-form :model="orderForm" :rules="rules" @submit="search" :ref="'orderDataForm' + data.id">
                                <el-form-item :label="active === 'buy' ? $t('c2c.need_pay') : $t('c2c.need_sell')" prop="buyAmount">
                                    <el-input v-model="orderForm.buyAmount" @input="changAmount('buyAmount', data.exchangeRate, 'orderDataForm' + data.id)">
                                        <div slot="append">
                                            <span class="all-in" @click="allIn(balance)">{{ $t('c2c.all') }}</span>
                                            <span class="coin-name">{{ active === 'buy' ? data.fiatSymbol : data.currency }}</span>
                                        </div>
                                    </el-input>
                                </el-form-item>
                                <div class="order-info-tips" v-if="active === 'sell'">
                                    <span>{{ $t('c2c.balance') }}</span>
                                    <span>{{ balance }}</span>
                                    <span>{{ active === 'buy' ? data.fiatSymbol : data.currency }}</span>
                                    <span class="exchange" @click="toExchange">{{ $t('c2c.transfer') }}</span>
                                </div>
                                <el-form-item :label="$t('c2c.will_receive')" prop="payAmount">
                                    <el-input v-model="orderForm.payAmount" @input="changAmount('payAmount', data.exchangeRate, 'orderDataForm' + data.id)">
                                        <div slot="append">
                                            <span class="coin-name">{{ active === 'buy' ? data.currency : data.fiatSymbol }}</span>
                                        </div>
                                    </el-input>
                                </el-form-item>
                                <div class="order-info-tips">
                                    <span class="exchange" @click="setPayment">{{ $t('c2c.set_payment') }}</span>
                                </div>
                                <el-form-item :label="$t('c2c.pay_type')" prop="paymentMethods">
                                    <el-select v-model="orderForm.paymentMethods">
                                        <el-option :label="getPaymentName(payment)" :value="payment" v-for="payment in data.paymentTypes" :key="payment">
                                            <span>{{ getPaymentName(payment) }}</span>
                                        </el-option>
                                    </el-select>
                                </el-form-item>
                                <div class="operate">
                                    <span class="cancel-button" @click="actionTrade = {}">{{ $t('c2c.cancel') }}</span>
                                    <span
                                        v-if="active === 'buy' && !expired"
                                        class="buy-button trading-operate"
                                        @click="trading(data, 'orderDataForm' + data.id)"
                                        >{{ $t('c2c.buy') }} {{ activeCoin }}</span
                                    >
                                    <span
                                        v-if="active === 'sell' && !expired"
                                        class="sell-button trading-operate"
                                        @click="trading(data, 'orderDataForm' + data.id)"
                                        >{{ $t('c2c.sell') }}{{ activeCoin }}</span
                                    >
                                    <span v-if="expired" class="reload-button trading-operate" @click="reload()">{{ $t('c2c.refresh_price') }}</span>
                                </div>
                            </el-form>
                        </div>
                    </div>
                </div>
            </div>
        </div>
    </div>
</template>

<script>
import Nav from './Nav.vue'
import { getAppContext } from 'utilities/helper';
// import { getAds, confirmOrder } from '@/api/c2c.js'

export default {
    // props: ['tab'],
    components: { Nav },

    data() {
        return {
            active: 'buy', // 当前页签
            activeCoin: this.sysconfig.c2c.tokens[0], //当前选择币种
            form: {
                amount: '',
                currency: ''
            }, // 筛选表单
            expired: false,
            orderForm: {
                buyAmount: '',
                payAmount: '',
                paymentMethods: ''
            }, // 订单表单
            actionTrade: {}, // 当前交易项
            tableData: [],
            fillData: [{ color: '#fff', background: '#1c2838', height: '16px', width: '16px', fontSize: '1rem', lineHeight: '16px' }],
            isMobile: false, // 是否移动端页面
            isTransfer: false, // 是否转换左右布局
            loading: false,
            balance: '', // 对应币种金额
            balanceData: [], // 账户各币种金额数据
            rules: {
                buyAmount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                payAmount: [{ required: true, message: '请输入金额', trigger: 'blur' }],
                paymentMethods: [{ required: true, message: '请选择支付方式', trigger: 'change' }]
            }
        }
    },

    created() {
        this.context = getAppContext()
        this.form.currency = this.sysconfig.c2c.fiatSymbols[0]
        let data = { currency: this.activeCoin, fiatSymbol: this.sysconfig.c2c.fiatSymbols[0], sellOrder: false }
        this.resetRule('buy')
        this.search(data)
        this.getBalance()
    },

    watch: {},

    computed: {
        // 获取用户支持支付方式
        getPaymentName() {
            return function (payType) {
                let data
                if (this.sysconfig.c2c) {
                    data = this.sysconfig.c2c.paymentTypes.find((item) => {
                        return item.key === payType && item.enabled
                    })
                }
                if (data) {
                    return data.name
                }
            }
        }
    },

    methods: {
        openTelegramChat(userName) {
            window.open(userName, '_blank')
        },
        // 获取买卖列表数据
        search(data) {
            $.callPostApi(this, '/api/v1/c2c/ads', data)
                .then((res) => {
                    this.tableData = res.data
                })
                .catch((err) => {
                    console.log(err)
                })
        },
        // 获取用户账户余额数据
        getBalance() {
            $.callPostApi(this, '/api/v1/balance/list').then((res) => {
                this.balanceData = res
                this.getBalanceData()
            })
        },
        // 更新对应币种账户余额数据
        getBalanceData() {
            let data
            if (this.active === 'buy') {
                data = this.balanceData.find((item) => {
                    return item.currency === this.form.currency
                })
            } else {
                data = this.balanceData.find((item) => {
                    return item.currency === this.activeCoin
                })
            }
            if (data) {
                this.balance = data.balance + data.frozen
            } else {
                this.balance = 0
            }
        },
        // 切换买卖类型
        changeType(type) {
            this.active = type
            this.resetRule(type)
            this.changeCurrency()
            // 切换买卖时重置表单
            this.resetField()
        },
        // 重置校验规则
        resetRule(type) {
            let ruleItem = [{ required: true, message: '请输入金额', trigger: 'blur' }]
            let validateAmount = (rule, value, callback) => {
                let max = this.actionTrade.maxAmount
                let min = this.actionTrade.minAmount
                if (value > max || value < min) {
                    callback(new Error('数量不在限额内'))
                } else {
                    callback()
                }
            }
            let rule = { validator: validateAmount, trigger: 'blur' }
            if (type === 'buy') {
                this.rules.buyAmount = ruleItem
                this.rules.payAmount.push(rule)
            } else {
                this.rules.payAmount = ruleItem
                this.rules.buyAmount.push(rule)
            }
        },
        // 重置表单
        resetField() {
            if (this.$refs['orderDataForm' + this.actionTrade.id] && this.$refs['orderDataForm' + this.actionTrade.id][0]) {
                this.$refs['orderDataForm' + this.actionTrade.id][0].resetFields()
            }
        },
        // 切换代币币种
        changeCoin(coin) {
            this.activeCoin = coin
            this.changeCurrency()
        },
        // 切换币种
        changeCurrency() {
            let data = { currency: this.activeCoin, fiatSymbol: this.form.currency, sellOrder: this.active !== 'sell' }
            this.search(data)
            this.getBalanceData()
        },
        // 输入金额联动
        changAmount(type, exchangeRate, ruleForm) {
            if (type === 'buyAmount') {
                if (this.active === 'buy') {
                    this.orderForm.payAmount = this.orderForm.buyAmount / exchangeRate
                    this.orderForm.payAmount = this.orderForm.payAmount.toFixed(2)
                } else {
                    this.orderForm.payAmount = this.orderForm.buyAmount * exchangeRate
                    this.orderForm.payAmount = this.orderForm.payAmount.toFixed(2)
                }
            } else {
                if (this.active === 'buy') {
                    this.orderForm.buyAmount = this.orderForm.payAmount * exchangeRate
                    this.orderForm.buyAmount = this.orderForm.buyAmount.toFixed(2)
                } else {
                    this.orderForm.buyAmount = this.orderForm.payAmount / exchangeRate
                    this.orderForm.buyAmount = this.orderForm.buyAmount.toFixed(2)
                }
            }
            this.$refs[ruleForm][0].validateField('payAmount')
            this.$refs[ruleForm][0].validateField('buyAmount')
        },
        // 全部
        allIn(amount) {
            this.orderForm.buyAmount = amount
        },
        // 调整资产页面划转
        toExchange() {
            this.$router.push('/user/balance')
        },
        // 设置收款方式
        setPayment() {
            this.$router.push('/user/bankaccount')
        },
        // 点击买卖按钮
        trade(data) {
            if (this.context && this.context.profile) {
                this.resetField()
                // 过滤掉被关闭的支付方式
                data.paymentTypes = data.paymentTypes.filter((item) => {
                    let data = this.sysconfig.c2c.paymentTypes.find((k) => {
                        return k.key === item
                    })
                    if (data && data.enabled) {
                        return data
                    }
                })
                this.actionTrade = data
                this.orderForm.paymentMethods = data.paymentTypes[0]
            } else {
                this.$router.push('/user/login')
            }
        },
        // 买卖下单
        trading(data, ruleForm) {
            this.loading = true
            this.$refs[ruleForm][0].validate((valid) => {
                if (valid) {
                    let form = {
                        AdsId: data.id,
                        Amount: this.active === 'buy' ? this.orderForm.payAmount : this.orderForm.buyAmount,
                        PaymentType: this.orderForm.paymentMethods
                    }
                    confirmOrder(form)
                        .then((res) => {
                            this.loading = false
                            this.$router.push({
                                path: '/trade/order',
                                query: {
                                    orderId: /* '192308290003000001'  */ res
                                }
                            })
                        })
                        .catch((err) => {
                            this.loading = false
                            console.log(err)
                        })
                } else {
                    this.loading = false
                }
            })
        },
        // 刷新价格
        reload() {}
    }
}
</script>
<style lang="less" scoped>
.container {
    width: -webkit-fill-available;
    width: -moz-available;
    padding: 39px 0;
}
.trade-box{
    width: -webkit-fill-available;
    width: -moz-available;
}

.coin-nav {
    background-color: #fff;
    padding: 0 2rem;
    border-top: 1px solid rgba(24, 26, 32, 0.04);
    box-shadow: 0 1px 10px -5px rgba(0, 0, 0, 0.2);
    position: relative;
    display: flex;
    align-items: center;
    flex-wrap: wrap;
    .trade-type {
        border: 1px solid #ccc;
        border-radius: 3px;
        display: inline-block;
        padding: 2px 3px;
        margin: 1rem 0;
        background-color: #fafafa;
        span {
            padding: 3px 16px;
            line-height: 26px;
            border-radius: 3px;
            font-weight: 500;
            cursor: pointer;
        }
    }
    .active-buy {
        background-color: #0ecb81;
        color: #fff;
    }
    .active-sell {
        background-color: #f6465d;
        color: #fff;
    }
    .coin-list {
        display: flex;
        align-items: center;
        span {
            margin: 0 16px;
            line-height: 56px;
            font-weight: 400;
            cursor: pointer;
        }
        .activeCoin {
            color: #f0b90b;
            border-bottom: 2px solid #f0b90b;
        }
    }
}
.filter-box {
    display: flex;
    justify-content: flex-start;
    background-color: #fff;
    padding: 0 2rem;
    > div {
        margin-right: 12px;
    }
    /deep/.el-form-item__label {
        color: #000922;
    }
}
.order-table {
    padding: 0 2rem 2rem;
    background-color: #fff;
    .table-header {
        color: #848e9c;
        display: flex;
        padding: 0 20px;
        font-size: 14px;
        border-bottom: 1px solid rgba(24, 26, 32, 0.04);
        > div {
            line-height: 40px;
        }
    }
    .table-list {
        display: flex;
        padding: 0 20px;
        border-bottom: 1px solid rgba(24, 26, 32, 0.04);
        .list,
        .trade-page {
            display: flex;
            width: 100%;
        }
        .list {
            padding: 20px 0;
            .pc-none {
                display: none;
            }
        }
        .user-info {
            font-size: 14px;
            display: flex;
        }
        .avatar-name {
            margin-right: 8px;
        }
        // .name {
        //     margin-bottom: 12px;
        // }
        .amount {
            margin-bottom: 8px;
        }
        .lable {
            font-size: 8px;
            color: #848e9c;
            min-width: 50px;
            margin-right: 8px;
            display: inline-block;
        }
        .trade-info {
            display: flex;
            font-size: 12px;
        }
        .turnover-rate {
            margin-right: 4px;
        }
        .turnover {
            margin-right: 4px;
        }
        .segmentation {
            color: rgba(35, 40, 41, 0.2);
            margin-right: 4px;
        }
        .price {
            font-size: 20px;
            color: #0b0e11;
            > span {
                font-size: 12px;
                margin-left: 4px;
                color: #212833;
            }
        }
        .trade-methods {
            font-size: 14px;
            > span {
                padding: 4px;
                // border-radius: 2px;
                // background-color: rgb(250, 250, 250);
            }
            .wechat {
                color: #2dc100;
            }
            .aliPay {
                color: #01a9f2;
            }
            .bank {
                color: #f0b90b;
            }
        }
    }
    .shadow {
        box-shadow: 0 4px 8px rgba(0, 0, 0, 0.08);
    }
    .operate {
        > span {
            padding: 6px 12px;
            line-height: 20px;
            font-size: 14px;
            font-weight: 500;
            border-radius: 4px;
            min-width: 52px;
            box-sizing: border-box;
            display: inline-block;
            text-align: center;
            color: #fff;
            width: 126px;
            text-align: center;
        }
        .buy-button {
            background-color: #0ecb81;
            cursor: pointer;
        }
        .sell-button {
            background-color: #f6465d;
            cursor: pointer;
        }
        .reload-button {
            background-color: #f0b90b;
            cursor: pointer;
        }
        .cancel-button {
            background-color: rgb(234, 236, 239);
            color: #0b0e11;
            cursor: pointer;
            width: 33%;
        }
        .trading-operate {
            width: 65%;
        }
    }
    .left-user-info {
        width: 59.9%;
        border-right: 1px solid rgba(24, 26, 32, 0.04);
        padding: 20px 0;
        .lable {
            min-width: auto;
        }
        .trading-name {
            display: flex;
            margin-bottom: 16px;
            .name {
                margin-left: 8px;
                margin-right: 14px;
            }
            .turnover {
                font-size: 12px;
                border-right: none;
            }
        }
        .trading-price {
            display: flex;
            padding-left: 4%;
            margin-bottom: 15px;
            > div {
                width: 48%;
            }
        }
        .trading-methods {
            display: flex;
            padding-left: 4%;
            margin-bottom: 23px;
            > div {
                width: 48%;
            }
            .detailPayType {
                margin-right: 8px;
                &:last-child {
                    margin-right: 0;
                }
            }
        }
        .buy-color {
            color: #0ecb81;
        }
        .sell-color {
            color: #ef3123;
        }
    }
    .form-info {
        width: 40%;
        padding: 24px;
        .all-in {
            color: #d0980b;
            cursor: pointer;
        }
        .order-info-tips {
            text-align: right;
            color: #76808f;
            font-size: 12px;
            margin-top: -16px;
            .exchange {
                color: #d0980b;
                cursor: pointer;
                display: inline-block;
                margin-left: 8px;
            }
        }
        /deep/.el-select {
            width: 100%;
        }
    }
    .user-info {
        flex: 260 1 0%;
        align-items: center;
    }
    .quantity-limit {
        flex: 242 1 0%;
    }
    .price {
        flex: 265 1 0%;
    }
    .trade-methods {
        flex: 185 1 0%;
    }
    .operate {
        flex: 126 1 0%;
    }
    /deep/.el-input-group__append {
        width: 80px;
    }
}
@media (max-width: 768px) {
    .order-table {
        .table-header {
            display: none;
        }
        .table-list {
            padding: 0;
        }
        .table-list .list {
            display: block;
            > div {
                margin: 12px 0;
            }
            .pc-none {
                display: inline-block;
                margin-right: 8px;
                font-size: 8px;
                color: #848e9c;
                min-width: 50px;
                padding: 0;
                background: none;
                margin-left: 0;
            }
        }
        .table-list .user-info {
            align-items: center;
        }
        .table-list .trade-page {
            display: block;
            .left-user-info {
                width: auto;
                border-right: none;
                padding: 16px;
                .trading-price,
                .trading-methods {
                    padding-left: 0;
                }
            }
            .form-info {
                width: auto;
                padding: 16px;
            }
        }
        .operate {
            text-align: right;
        }
    }
}
</style>
