<template>
    <el-dialog :visible.sync="dialogVisible" :show-close="true" :title="'确认付款'" width="585px">
        <el-form :model="paymentData" :rules="rules" ref="paymentVoucher" @submit="submit">
            <el-form-item :label="'支付凭证'" prop="ProofPhoto">
                <el-upload
                    ref="upload"
                    class="avatar-uploader"
                    :show-file-list="false"
                    v-model="paymentData.ProofPhoto"
                    name="ProofPhoto"
                    :multiple="false"
                    accept="image/png, image/jpeg"
                    :auto-upload="false"
                    action="#"
                    :on-change="fileChanged"
                >
                    <img v-if="imageUrl" :src="imageUrl" class="avatar" />
                    <div v-else class="upload-box">
                        <i class="el-icon-plus avatar-uploader-icon"></i>
                        <div class="upload-title">上传</div>
                    </div>
                </el-upload>
            </el-form-item>
            <div class="upload-tip">请上传jpg / jpeg / png / pdf文件，每个文件大小不超过10MB</div>
        </el-form>
        <div slot="footer" class="footer-button">
            <span class="cancel" @click="closeDialog">取消</span>
            <span class="confirm" :class="{ 'disabled': !canConfirm }">确认付款</span>
        </div>
        <div class="tips-box">
            <p>* 请确认您已向卖家支付订单要求的相应账户及金额。</p>
            <p>* 请确认您是否按订单填写的真实姓名的银行账户付款。</p>
            <p>* 恶意点击或未支付订单正确金额，账户可能会被冻结，请谨慎操作。</p>
        </div>
    </el-dialog>
</template>

<script>
export default {
    props: ['orderNumber'],

    data() {
        return {
            canConfirm: false, // 是否可以提交
            dialogVisible: false,
            imageUrl: '', // 上传图片回显url
            paymentData: {
                // 上传凭证
                ProofPhoto: null
            },
            rules: {
                ProofPhoto: [{ required: true, message: ' ', trigger: 'blur' }]
            }
        }
    },

    watch: {
        // 点击图片后放开提交禁用
        'paymentData.ProofPhoto'() {
            if (this.paymentData.ProofPhoto) {
                this.canConfirm = true
            } else {
                this.canConfirm = false
            }
        }
    },

    methods: {
        // 转出
        submit() {
            this.$refs['paymentVoucher'].validate((valid) => {
                if (valid) {
                    this.canConfirm = false
                } else {
                    return
                }
            })
        },
        // 上传图片
        fileChanged(file) {
            if (!file.raw) return
            if (file.size > 5 * 1024 * 1024) {
                // 不超过 5M
                this.$message({
                    message: this.$t('idverification.err_too_big_file', { fileSize: Math.ceil(file.size / 1024.0) }),
                    type: 'error'
                })
                return
            }
            this.imageUrl = URL.createObjectURL(file.raw) // 回显图片
            let ext = file.name.substring(file.name.lastIndexOf('.') + 1).toLowerCase() // 文件类型获取

            if (ext === 'png' || ext === 'jpg' || ext === 'jpeg') {
                this.paymentData.ProofPhoto = file.raw
            } else {
                // 文件类型选择错误提示
                this.$message({
                    message: this.$t('idverification.err_invalid_file_type'),
                    type: 'error'
                })
                this.$refs.upload.clearFiles()
                this.imageUrl = ''
            }
        },
        // 关闭弹窗
        closeDialog() {
            this.dialogVisible = false
            this.$refs['paymentVoucher'].resetFields()
            this.imageUrl = ''
        }
    }
}
</script>
<style lang="less" scoped>
/deep/.el-upload {
    width: 100%;
}
.upload-box {
    border: 1px dashed rgba(24, 26, 32, 0.1);
}
.upload-tip {
    text-align: center;
}
.tips-box {
    p {
        margin-top: 20px;
        color: #707a8a;
    }
}
.footer-button {
    display: flex;
    span {
        width: 100%;
        font-size: 16px;
        border-radius: 4px;
        line-height: 24px;
        padding: 12px 24px;
        box-sizing: border-box;
        text-align: center;
        cursor: pointer;
    }
    .cancel {
        margin-right: 8px;
        background-color: rgb(234, 236, 239);
    }
    .confirm {
        background-color: #f0b90b;
    }
    .disabled {
        background-color: rgb(234, 236, 239);
        color: #000922;
        cursor: no-drop;
    }
}
</style>
