<!-- 我的历史订单 -->
<template>
    <section class="page container">
        <div class="top-link"></div>
        <div class="fiat-order">
            <div class="order-filter-box">
                <el-form :inline="true" :model="formInline" class="demo-form-inline">
                    <!-- <el-form-item label="审批人">
                        <el-input v-model="formInline.user" placeholder="审批人"></el-input>
                    </el-form-item> -->
                    <!-- <el-form-item label="状态">
                        <el-select v-model="formInline.region" placeholder="全部状态">
                            <el-option label="全部状态" value="all"></el-option>
                            <el-option label="待付款" value="shanghai"></el-option>
                            <el-option label="待放币" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item label="交易类型">
                        <el-select v-model="formInline.region" placeholder="交易类型">
                            <el-option label="全部状态" value="all"></el-option>
                            <el-option label="购买" value="shanghai"></el-option>
                            <el-option label="出售" value="beijing"></el-option>
                        </el-select>
                    </el-form-item>
                    <el-form-item>
                        <el-button type="primary" @click="onSubmit">查询</el-button>
                    </el-form-item> -->
                </el-form>
            </div>
            <div class="table">
                <el-table :data="tableData" class="table-box" style="width: 1200px">
                    <el-table-column prop="orderId" :label="$t('c2c.order_id')" width="180"> </el-table-column>
                    <el-table-column prop="sellOrder" :label="$t('c2c.sell_type')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.sellOrder === true ? $t('c2c.sell') : $t('c2c.buy') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('c2c.order_total')">
                        <template slot-scope="scope">
                            <span>{{ scope.row.exchangeRate && scope.row.amount ? (scope.row.exchangeRate * scope.row.amount).toFixed(2) : '' }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="exchangeRate" :label="$t('c2c.exchangeRate')"> </el-table-column>
                    <el-table-column prop="amount" :label="$t('c2c.amount')"> </el-table-column>
                    <el-table-column prop="timeCreated" :label="$t('c2c.timeCreated')" width="180">
                        <template slot-scope="scope">
                            <span>{{ new Date(scope.row.timeCreated).formatDateTime() }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column prop="status" :label="$t('c2c.order_status')">
                        <template slot-scope="scope">
                            <span v-if="scope.row.status === 0">{{ $t('c2c.order_status0') }}</span>
                            <span v-if="scope.row.status === 1">{{ $t('c2c.order_status1') }}</span>
                            <span v-if="scope.row.status === 2">{{ $t('c2c.order_status2') }}</span>
                            <span v-if="scope.row.status === 3">{{ $t('c2c.order_status3') }}</span>
                            <span v-if="scope.row.status === 4">{{ $t('c2c.order_status4') }}</span>
                            <span v-if="scope.row.status === 5">{{ $t('c2c.order_status5') }}</span>
                        </template>
                    </el-table-column>
                    <el-table-column :label="$t('c2c.order_operate')">
                        <template slot-scope="scope">
                            <router-link :to="`/trade/order?orderType=merchant&orderId=${scope.row.orderId}`">{{ $t('c2c.order_check') }}</router-link>
                        </template>
                    </el-table-column>
                </el-table>
                <div class="table-mobile">
                    <div class="body">
                        <div class="card-item" v-for="(item, index) in tableData" :key="index">
                            <div>
                                <div class="label">{{ $t('c2c.order_id') }}:</div>
                                <div class="val">{{ item.orderId }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.sell_type') }}:</div>
                                <div class="val">{{ item.sellOrder === true ? $t('c2c.sell') : $t('c2c.buy') }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.order_total') }}:</div>
                                <div class="val">{{ item.exchangeRate && item.amount ? (item.exchangeRate * item.amount).toFixed(2) : '' }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.exchangeRate') }}:</div>
                                <div class="val">{{ item.exchangeRate }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.amount') }}:</div>
                                <div class="val">{{ item.amount }}</div>
                            </div>
                            <div>
                                <div class="label">{{ $t('c2c.timeCreated') }}:</div>
                                <div class="val">{{ new Date(item.timeCreated).formatDateTime() }}</div>
                            </div>
                            <div>
                                <div class="label">状态:</div>
                                <div class="val">
                                    <span v-if="item.status === 0">{{ $t('c2c.order_status0') }}</span>
                                    <span v-if="item.status === 1">{{ $t('c2c.order_status1') }}</span>
                                    <span v-if="item.status === 2">{{ $t('c2c.order_status2') }}</span>
                                    <span v-if="item.status === 3">{{ $t('c2c.order_status3') }}</span>
                                    <span v-if="item.status === 4">{{ $t('c2c.order_status4') }}</span>
                                    <span v-if="item.status === 5">{{ $t('c2c.order_status5') }}</span>
                                </div>
                            </div>
                            <div>
                                <div class="label"></div>
                                <div class="val">
                                    <router-link :to="`/trade/order?orderType=merchant&orderId=${item.orderId}`">{{ $t('c2c.order_check') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div v-if="!(tableData.length === 0)">
                    <el-button v-if="!noMoreData" class="more-btn" block @click="more()" :loading="moreLoading">{{ $t('c2c.check_more') }}</el-button>
                </div>
            </div>
        </div>
    </section>
</template>

<script>
export default {
    // components: { Card },
    data() {
        return {
            formInline: {
                user: '',
                region: ''
            },
            tableData: [],
            noMoreData: false,
            moreLoading: false
        }
    },
    created() {
        this.getOrderData()
    },
    mounted() {},
    methods: {
        onSubmit() {
            console.log('submit!')
        },
        more() {
            this.moreLoading = true
            let uid = this.tableData[this.tableData.length - 1].uid - 1
            this.getOrderData(uid)
        },
        getOrderData(until = 0x7fffffff) {
            if (this.noMoreData) {
                return false
            }
            let data = { until, status: -1 }
            if (!until) {
                data.until = ''
            }
            $.callPostApi(this, '/api/v1/c2c/merchant/orders', data).then((res) => {
                if(res.errcode === 0){
                    if (res.data.length < 20) {
                        this.noMoreData = true
                    } else {
                        this.noMoreData = false
                    }
                    res.data.forEach((item) => {
                        this.tableData.push(item)
                    })
                    this.moreLoading = false
                }
            })
            .catch((err) => {
                console.log(err)
                this.moreLoading = false
            })
        }
    }
}
</script>
<style lang="less" scoped>
.top-link {
    height: 56px;
    line-height: 56px;
}
.fiat-order {
    max-width: 1136px;

    .table {
        max-width: 1136px;
    }
    .el-button.el-button--primary {
        line-height: normal;
    }
}
.table {
    width: 100%;
    margin-bottom: 100px;
    // width: 900px;
}
.more-btn {
    display: block;
    width: 100%;
    box-shadow: none;
}
.table-mobile {
    display: none;
}
@media (max-width: 768px) {
    .table-box {
        display: none;
    }
    .table-mobile {
        display: block;
        margin: 0 20px;
        width: auto;
        // padding: 0 20px;
    }
    .card-item {
        background-color: #fff;
        border-radius: 8px;
        box-shadow: 0 0 10px 0 rgba(0, 0, 0, 0.06);
        box-sizing: border-box;
        display: flex;
        flex-direction: column;
        margin: 0 0 20px 0px;
        padding: 25px 15px 16px;
        position: relative;
        font-size: 15px;
        > div {
            margin-bottom: 12px;
            display: flex;
            justify-content: space-between;
        }
    }
}
</style>
