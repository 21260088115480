import Vue from 'vue'
import {
    Pagination,
    Dialog,
    Dropdown,
    DropdownMenu,
    DropdownItem,
    // Menu,
    // Submenu,
    Input,
    InputNumber,
    Radio,
    RadioGroup,
    RadioButton,
    Checkbox,
    Switch,
    Select,
    Option,
    OptionGroup,
    Button,
    Table,
    TableColumn,
    Tag,
    // Tooltip,
    Form,
    FormItem,
    Tabs,
    TabPane,
    Row,
    Col,
    Upload,
    Loading,
    MessageBox,
    Message,
    Carousel,
    CarouselItem,
    Drawer,
    Slider,
    Popover,
    Empty,
    Card,
    Steps,
    Step,
    Timeline,
    TimelineItem,
    Progress,
    Tooltip,
    Alert
} from 'element-ui'

Vue.use(Pagination)
Vue.use(Dialog)
Vue.use(Dropdown)
Vue.use(DropdownMenu)
Vue.use(DropdownItem)
// Vue.use(Menu)
// Vue.use(Submenu)
Vue.use(Input)
Vue.use(InputNumber)
Vue.use(Radio)
Vue.use(RadioGroup)
Vue.use(RadioButton)
Vue.use(Checkbox)
Vue.use(Switch)
Vue.use(Select)
Vue.use(Option)
Vue.use(OptionGroup)
Vue.use(Button)
Vue.use(Table)
Vue.use(Tag)
Vue.use(TableColumn)
// Vue.use(Tooltip)
Vue.use(Form)
Vue.use(FormItem)
Vue.use(Tabs)
Vue.use(TabPane)
Vue.use(Row)
Vue.use(Col)
Vue.use(Upload)
Vue.use(Loading.directive)
Vue.use(Carousel)
Vue.use(CarouselItem)
Vue.use(Drawer)
Vue.use(Slider)
Vue.use(Popover)
Vue.use(Empty)
Vue.use(Card)
Vue.use(Steps)
Vue.use(Step)
Vue.use(TimelineItem)
Vue.use(Timeline)
Vue.use(Progress)
Vue.use(Tooltip)
Vue.use(Alert)

Vue.prototype.$loading = Loading.service
Vue.prototype.$msgbox = MessageBox
Vue.prototype.$alert = MessageBox.alert
Vue.prototype.$confirm = MessageBox.confirm
Vue.prototype.$prompt = MessageBox.prompt
// Vue.prototype.$notify = Notification
Vue.prototype.$message = Message
