<!-- 商家申请 -->
<template>
    <section class="mer-page">
        <!-- <div class="top-link"></div> -->
        <div class="merchant-application">
            <h3 data-bn-type="text" class="top-title">{{ $t('c2c.merchant_title') }}</h3>
            <p data-bn-type="text" class="css-tip">{{ $t('c2c.merchant_tip', { 'platform': sysconfig.shortName }) }}</p>
            <div class="advertise-content">
                <div class="advertise-content-box">
                    <img src="@/assets/images/c2c/merApplicationRewardIcon.png" />
                    <h3 data-bn-type="text" class="advertise-title">{{ $t('c2c.advertise_title') }}</h3>
                    <p data-bn-type="text" class="advertise-p">{{ $t('c2c.advertise_p') }}</p>
                </div>
                <div class="advertise-content-box">
                    <img src="@/assets/images/c2c/merApplicationFeeIcon.png" />
                    <h3 data-bn-type="text" class="advertise-title">{{ $t('c2c.advertise_tag') }}</h3>
                    <p data-bn-type="text" class="advertise-p">{{ $t('c2c.advertise_desc') }}</p>
                </div>
                <div class="advertise-content-box">
                    <img src="@/assets/images/c2c/merApplicationCsIcon.png" />
                    <h3 data-bn-type="text" class="advertise-title">{{ $t('c2c.advertise_desc1') }}</h3>
                    <p data-bn-type="text" class="advertise-p">{{ $t('c2c.advertise_desc2') }}</p>
                </div>
            </div>
            <div class="apply">
                <div class="apply-content">
                    <div class="title">{{ $t('c2c.apply_title') }}</div>
                    <div class="status">
                        <div class="mail">
                            <div class="p">
                                <i class="el-icon-success"></i>
                                <div class="t">{{ $t('c2c.apply_desc') }}</div>
                            </div>
                        </div>
                        <div class="identification">
                            <div class="p">
                                <i class="el-icon-success"></i>
                                <div class="t">{{ $t('c2c.apply_idcard') }}</div>
                            </div>
                        </div>
                        <div class="property">
                            <div class="p">
                                <i class="el-icon-success"></i>
                                <div class="t">
                                    {{ $t('c2c.property_desc') }} {{ sysconfig.c2c.margin }}
                                    <el-tooltip class="item" effect="dark" :content="$t('c2c.property_tip')" placement="bottom">
                                        <i class="el-icon-info"></i
                                    ></el-tooltip>
                                </div>
                            </div>
                            <div class="right-box">
                                <div class="link-box">
                                    <router-link to="/user/balance" class="link">{{ $t('c2c.property_cz') }}</router-link>
                                </div>
                            </div>
                        </div>
                    </div>
                </div>
                <div class="apply-btn-box" v-if="merchantStatus === null">
                    <el-button class="apply-btn" type="primary" :disabled="isApplyBtn" @click="isInputName = true">{{ $t('c2c.property_btn') }}</el-button>
                </div>
            </div>
        </div>
        <el-dialog :title="$t('c2c.merchant_dialog_title')" :visible.sync="isInputName">
            <el-form :model="merchantForm" :rules="merchantRules" ref="merchantForm">
                <el-form-item :label="$t('c2c.merchant_dialog_name')" required prop="name">
                    <el-input v-model="merchantForm.name" autocomplete="off" maxlength="32"></el-input>
                </el-form-item>
                <el-form-item :label="$t('c2c.merchant_dialog_mark')">
                    <el-input v-model="merchantForm.remarks" autocomplete="off" maxlength="64"></el-input>
                </el-form-item>
                <el-form-item :label="$t('c2c.merchant_address')">
                    <el-input v-model="merchantForm.Contact" autocomplete="off" maxlength="64"></el-input>
                </el-form-item>
            </el-form>
            <div slot="footer" class="dialog-footer">
                <el-button @click="isInputName = false">{{ $t('c2c.cancel') }}</el-button>
                <el-button type="primary" @click="merchantSubmitForm('merchantForm')" :loading="false">{{ $t('c2c.confirm') }}</el-button>
            </div>
        </el-dialog>
    </section>
</template>

<script>
export default {
    data() {
        return {
            isApplyBtn: false,
            isInputName: false,
            merchantStatus: false,
            merchantForm: {
                name: '',
                remarks: '',
                Contact: ''
            },
            merchantRules: {
                name: [{ required: true, message: this.$t('c2c.merchant_dialog_error'), trigger: 'blur' }]
            }
        }
    },
    created() {
        $.callPostApi(this, '/api/v1/c2c/merchant/application').then((res) => {
            if(res.errcode === 0){
                if (res.data && res.data.status && res.data.status !== undefined) {
                    this.merchantStatus = res.data.status
                } else {
                    this.merchantStatus = null
                }
            }
        })
        .catch((err) => {
            console.log(err)
        })
    },
    mounted() {},
    methods: {
        merchantSubmitForm(ref) {
            this.$refs[ref].validate((valid) => {
                if (valid) {
                    // alert('submit!')
                    var data = new FormData()
                    data.append('name', this.merchantForm.name)
                    data.append('remarks', this.merchantForm.remarks)
                    data.append('Contact', this.merchantForm.Contact)
                    $.callPostApi(this, '/api/v1/c2c/merchant/application', data).then((res) => {
                        if(res.errcode === 0){
                            this.$message({ message: this.$t('c2c.merchant_dialog_success'), type: 'success' })
                            window.history.go(-1)
                        }
                    })
                    .catch((err) => {
                        console.log(err)
                    })
                } else {
                    console.log('error submit!!')
                    return false
                }
            })
        }
    }
}
</script>
<style lang="less" scoped>
.mer-page {
    margin-bottom: 0 !important;
}
.top-link {
    height: 56px;
    line-height: 56px;
}
.merchant-application {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    width: 100%;
    height: 100%;
    min-height: 100vh;
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    color: white;
    padding-left: 16px;
    padding-right: 16px;
    padding-bottom: 32px;
    text-align: center;
    background: url(@/assets/images/c2c/merApplicationBg.png) no-repeat center center fixed;
    background-size: cover;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media screen and (min-width: 767px) {
    .merchant-application {
        padding-left: 24px;
        padding-right: 24px;
        padding-bottom: 0;
    }
}
@media screen and (min-width: 1023px) {
    .merchant-application {
        padding-left: 120px;
        padding-right: 120px;
    }
}
.top-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 600;
    font-size: 32px;
    line-height: 40px;
    padding-top: 16px;
    font-size: 24px;
}
@media screen and (min-width: 767px) {
    .top-title {
        font-size: 32px;
    }
}
.css-tip {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 500;
    font-size: 16px;
    line-height: 24px;
    color: #707a8a;
    margin-top: 4px;
}
.advertise-content {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    margin-top: 10px;
    width: 100%;
    max-width: 1200px;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    margin-bottom: 0;
    -webkit-flex-wrap: wrap;
    -ms-flex-wrap: wrap;
    flex-wrap: wrap;
}
@media screen and (min-width: 767px) {
    .advertise-content {
        margin-top: 42px;
        -webkit-box-pack: justify;
        -webkit-justify-content: space-between;
        -ms-flex-pack: justify;
        justify-content: space-between;
    }
}
@media screen and (min-width: 767px) {
    .advertise-content {
        margin-bottom: 0;
    }
}
.advertise-content-box {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    display: -webkit-box;
    display: -webkit-flex;
    display: -ms-flexbox;
    display: flex;
    height: auto;
    text-align: center;
    width: 343px;
    border-radius: 10px;
    margin-top: 28px;
    padding-left: 24px;
    padding-right: 24px;
    background-image: linear-gradient(180deg, #474d57 -100%, #1e2329 100%);
    box-shadow: 0px 7px 14px rgba(0, 0, 0, 0.08), 0px 3px 6px rgba(0, 0, 0, 0.08), inset 0px 0px 2px rgba(94, 102, 115, 0.9);
    -webkit-flex-direction: column;
    -ms-flex-direction: column;
    flex-direction: column;
    -webkit-box-pack: center;
    -webkit-justify-content: center;
    -ms-flex-pack: center;
    justify-content: center;
    -webkit-align-items: center;
    -webkit-box-align: center;
    -ms-flex-align: center;
    align-items: center;
}
@media screen and (min-width: 767px) {
    .advertise-content-box {
        width: 230px;
        margin-top: 0;
    }
}
@media screen and (min-width: 1023px) {
    .advertise-content-box {
        width: 384px;
    }
}
.advertise-content-box > img {
    // margin-top: -13px;
    padding-top: 24px;
    max-width: 94px;
}
.advertise-content-box > h3 {
    font-size: 18px;
    margin-top: 16px;
}
@media screen and (min-width: 767px) {
    .advertise-content-box > h3 {
        font-size: 20px;
        margin-top: 20px;
    }
}
.advertise-content-box > p {
    color: #707a8a;
    margin-top: 8px;
    margin-bottom: 16px;
}
@media screen and (min-width: 767px) {
    .advertise-content-box > p {
        margin-bottom: 32px;
    }
}
.advertise-title {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 400;
    font-size: 20px;
    line-height: 28px;
}
.advertise-p {
    box-sizing: border-box;
    margin: 0;
    min-width: 0;
    font-weight: 400;
    font-size: 14px;
    line-height: 20px;
}
.el-icon-success {
    color: #0ecb81;
}
.el-icon-error {
    color: #ef3123;
}
.apply {
    box-sizing: border-box;
    margin: 16px 0px 120px;
    min-width: 0px;
    // display: flex;
    width: 100%;
    max-width: 1200px;
    // flex-wrap: wrap;
}
.apply-content {
    box-sizing: border-box;
    margin: 16px auto;
    min-width: 0px;
    display: flex;
    border-radius: 10px;
    width: 100%;
    border: 1px solid rgb(71, 77, 87);
    padding: 24px 16px;
    flex-direction: column;
    text-align: left;
    width: 100%;
    .title {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        font-weight: 500;
        font-size: 16px;
        line-height: 24px;
        color: rgb(112, 122, 138);
        text-align: left;
    }
    .status {
        box-sizing: border-box;
        margin: 8px 0px 0px;
        min-width: 0px;
        display: flex;
        flex-direction: column;
        > div {
            margin-top: 8px;
        }
        i {
            display: inline-block;
            box-sizing: border-box;
            margin: 0.5px 10px 0px 0px;
            min-width: 0px;
            max-width: 100%;
            width: 18px;
            height: 18px;
        }
        .p {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            display: flex;
            flex-wrap: wrap;
            align-items: flex-start;
        }
        .t {
            box-sizing: border-box;
            margin: 0px;
            min-width: 0px;
            font-weight: 400;
            font-size: 14px;
            line-height: 20px;
            flex: 1 0 50%;
            max-width: 396px;
        }
    }
    .mail {
        box-sizing: border-box;
        margin: 0px;
        min-width: 0px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
    }
    .property {
        box-sizing: border-box;
        margin: 16px 0px 0px;
        min-width: 0px;
        display: flex;
        -webkit-box-pack: justify;
        justify-content: space-between;
        flex-wrap: wrap;
        -webkit-box-align: center;
        align-items: center;
        .right-box {
            box-sizing: border-box;
            margin: 4px 0px 0px 28px;
            min-width: 0px;
            display: flex;
            .link {
                height: 24px;
                line-height: 24px;
                background: #fcd535;
                padding: 4 20px;
                box-sizing: border-box;
                font-size: 12px;
                font-weight: 500;
                text-align: center;
                min-width: 40px;
                word-break: keep-all;
                color: #181a20;
                border-radius: 4px;
                min-height: 24px;
                border: none;
                background-image: none;
                background-color: rgb(252, 213, 53);
                overflow: hidden;
                text-overflow: ellipsis;
                white-space: nowrap;
                display: block;
                min-width: 80px;
                cursor: pointer;
                &:hover {
                    opacity: 0.6;
                }
            }
        }
        .el-icon-info {
            font-size: 16px;
            cursor: pointer;
        }
    }
}
.apply-btn-box {
    .apply-btn {
        margin: 24px 0px 0px;
        cursor: pointer;
        overflow: hidden;
        text-overflow: ellipsis;
        white-space: nowrap;
        display: inline-flex;
        box-sizing: border-box;
        font-weight: 500;
        text-align: center;
        padding: 10px 16px;
        line-height: 20px;
        min-width: 60px;
        word-break: keep-all;
        color: #181a20;
        border-radius: 4px;
        min-height: 24px;
        border: none;
        background-image: none;
        background-color: #fcd535;
        width: 100%;
        display: flex;
        justify-content: center;
        margin: 0 auto;
        /deep/span {
            color: #181a20;
            transition: color 0.4s;
        }
        &:hover {
            background: #ffcc00;
            /deep/span {
                color: #fff;
            }
            color: #fff;
        }
    }
    .el-button--primary.is-disabled,
    .el-button--primary.is-disabled:hover {
        background: #e0c96c;
        color: #181a20;
    }
}
.el-tooltip__popper.is-dark {
    max-width: 300px;
    background-color: #5e6673;
}
@media screen and (min-width: 767px) {
    .apply-content {
        width: 50%;
    }
    .apply-btn-box .apply-btn {
        width: 334px;
    }
}
@media (max-width: 768px) {
    .advertise-content-box {
        max-width: none;
    }
}
/deep/.el-dialog {
    width: 85%;
    max-width: 600px;
}
</style>
