<template>
    <div class="nav-box">
        <div class="left-box">
            <!-- <span @click="routerTo('/otc')">{{ $t('otc.title') }}</span> -->
            <span @click="routerTo('/trade')">{{ 'C2C' }}</span>
        </div>
        <div class="right-box">
            <span @click="routerTo('/fiatorder')">{{ $t('c2c.order_history') }}</span>
            <span @click="routerTo('/myAd')" v-if="userData.status === 4">{{ $t('c2c.my_ad') }}</span>
            <span @click="routerTo('/userCenter')" v-if="userData.status === 4">{{ $t('c2c.user_center') }}</span>
            <span @click="routerTo('/tradeAd')" v-if="userData.status === 4">{{ $t('c2c.trade_ad') }}</span>
            <span @click="routerTo('/MerchantApplication')" v-else>{{ $t('c2c.merchant_application') }}</span>
        </div>
    </div>
</template>

<script>
export default {
    components: {},

    data() {
        return {
            userData: {}
        }
    },

    created() {
        this.getMerchantApplicationData()
    },

    watch: {},

    computed: {},

    methods: {
        // 获取用户是否是商户
        getMerchantApplicationData() {
             $.callPostApi(this, '/api/v1/c2c/merchant/application').then((res) => {
                if(res.errcode === 0){
                    this.userData = res.data
                }
            })
            .catch((err) => {
                console.log(err)
            })
        },
        routerTo(path) {
            this.$router.push(path)
        }
    }
}
</script>
<style lang="less" scoped>
.nav-box {
    display: flex;
    justify-content: space-between;
    font-size: 16px;
    line-height: 54px;
    padding: 0 2rem;
    background-color: #fff;
    .left-box span {
        padding-right: 8px;
        display: inline-block;
        cursor: pointer;
    }
    .right-box span {
        padding-left: 8px;
        display: inline-block;
        cursor: pointer;
    }
}
</style>
