<template>
  <section class="page home-page">
    <section class="pro_layout">
      <loading-indicator v-if="!symbols" />
      <div v-else>
        <div class="home-top">
          <top-banners v-if="context.homeConfig" :home_config="context.homeConfig" />
        </div>

        <!-- top notice -->
        <div class="page-part" v-if="
          context.homeConfig &&
          context.homeConfig.promptedNotices &&
          context.homeConfig.promptedNotices.length
        ">
          <home-notices :promptedNotices="context.homeConfig.promptedNotices" />
        </div>

        <!-- major nav icons for sm screens -->
        <div class="d-block d-md-none sm-navs">
          <div class="container">
            <div class="row">
              <div class="col">
                <router-link to="/user/login">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-login" />
                  </svg>
                  {{ $t("general.login") }}/{{ $t("general.register") }}
                </router-link>
              </div>
              <div class="col">
                <!-- Display a tab for the savings feature when it was enabled. -->
                <router-link v-if="sysconfig.savingsEnabled" to="/saving">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                  </svg>
                  {{ $t("home.header_saving", { platform: sysconfig.name }) }}
                </router-link>
                <router-link v-else to="/user/balance">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-wallet" />
                  </svg>
                  {{
                    $t(
                      sysconfig.is_hkmy
                        ? "home.header_buy_crypto_my"
                        : "home.header_buy_crypto"
                    )
                  }}
                </router-link>
              </div>
              <div class="col">
                <router-link to="/startup">
                  <svg class="svg-icon" viewBox="0 0 256 256">
                    <use xlink:href="/dist/svg/icons.svg#v4.2-assets" />
                  </svg>
                  {{
                    $t(
                      sysconfig.is_hk ? "general.startup_hk" : "general.startup"
                    )
                  }}
                </router-link>
              </div>
              <div class="col">
                <router-link to="/notices">
                  <div class="svg-box">
                    <svg class="svg-icon notice-svg" viewBox="0 0 256 256">
                      <use class="use-svg" xlink:href="/dist/svg/icons.svg#v4.2-notice" />
                    </svg>
                  </div>
                  {{ $t("notices.notice_title") }}
                </router-link>
              </div>
            </div>
          </div>
        </div>

        <inline-svg-icons :symbols="symbols" />
        <!-- <home-quotes ref="quotes" :symbols="symbols" /> -->
        <!-- home-top -->
        <section class="home-top">
          <div class="container home-top-desp-last">
            <div class="top">
              <div class="home-top-left">
                <div class="title">
                  {{ $t('home.h1') }}
                </div>
                <div class="title-desp">
                  {{ $t('home.h1_desp') }}
                </div>
                <div class="home-top-button" @click="gotoRegister">
                  <div>
                    {{ $t('register.label_register') }}
                  </div>
                  <div class="register-start register-start-top">
                    <img src="@/assets/images/de-twaa/register-start.png" alt="">
                  </div>
                </div>
              </div>
              <div class="home-top-right">
                <img src="@/assets/images/de-twaa/home-top-right-2x.png" alt="">
              </div>
            </div>
            <div class="down">
              <div class="home-top-desp1">
                <img src="@/assets/images/de-twaa/home-top-desp1.png" alt="">
              </div>
              <div class="home-top-desp2 down-child">
                <div class="flex-column  home-top-child">
                  <div class="d-flex align-items-center">
                    <div class="desp2-title">{{ "ETH" }}</div>
                    <div class="desp2-title-desp2">{{ "/USDT" }}</div>
                  </div>
                  <div class="desp2-register" :class="['de','es'].includes($i18n.locale)?'small-size':''">
                    {{ $t('register.label_register') }}
                  </div>
                </div>
                <img src="@/assets/images/de-twaa/home-top-desp2-2x.png" alt="" class="home-top-desp2-img">
              </div>
              <div class="home-top-desp3 down-child">
                <div class="flex-column home-top-child">
                  <div class="d-flex align-items-center">
                    <div class="desp3-title">{{ "BTC" }}</div>
                    <div class="desp2-title-desp2" >{{ "/USDT" }}</div>
                  </div>
                  <div class="desp3-register" :class="['de','es'].includes($i18n.locale)?'small-size':''">
                    {{ $t('register.label_register') }}
                  </div>
                </div>
                <img src="@/assets/images/de-twaa/home-top-desp3-2x.png" alt="" class="desp4-img">
              </div>
              <div class="home-top-desp2 home-top-desp2-mb down-child">
                <div class="flex-column">
                  <div class="d-flex align-items-center">
                    <div class="desp2-title">{{ "LTC" }}</div>
                    <div class="desp2-title-desp2">{{ "/USDT" }}</div>
                  </div>
                  <div class="desp2-register" :class="['de','es'].includes($i18n.locale)?'small-size':''">
                    {{ $t('register.label_register') }}
                  </div>
                </div>
                <img src="@/assets/images/de-twaa/home-top-desp4-2x.png" alt="" class="desp4-img">
              </div>
              <div class="home-top-desp4 down-child" :class="['de','es'].includes($i18n.locale)?'small-style':''">
                <div class="flex-column">
                  <div class="d-flex align-items-center">
                    <div class="desp3-title">{{ "ETH" }}</div>
                    <div class="desp2-title-desp2">{{ "/USDT" }}</div>
                  </div>
                  <div class="desp3-register" :class="['de','es'].includes($i18n.locale)?'small-size':''">
                    {{ $t('register.label_register') }}
                  </div>
                </div>
              </div>
              <div class="home-top-desp5">
              </div>
            </div>
          </div>
        </section>
        <!-- home-middle -->
        <section class="home-middle">
          <div class="container">
            <div class="top">
              <div class="home-top-left">
                <div class="title-middle">
                  {{ $t('home.guide_trade_long') }}
                </div>
                <div class="title-desp-middle">
                  {{ $t('home.home_efficient_trading') }}
                </div>
              </div>
              <div class="home-middle-right">
                <div class="d-flex">
                  <div>
                    <img src="@/assets/images/de-twaa/middle6-2x.png" alt="" class="img">
                  </div>
                  <div>
                    <img src="@/assets/images/de-twaa/middle7-2x.png" alt="" class="img">
                  </div>
                  <div>
                    <img src="@/assets/images/de-twaa/middle8-2x.png" alt="" class="img">
                  </div>
                  <div>
                    <img src="@/assets/images/de-twaa/middle9-2x.png" alt="" class="img">
                  </div>
                </div>
                <div class="d-flex">
                  <div>
                    <img src="@/assets/images/de-twaa/middle10-2x.png" alt="" class="img">
                  </div>
                  <div>
                    <img src="@/assets/images/de-twaa/middle11-2x.png" alt="" class="img">
                  </div>
                  <div>
                    <img src="@/assets/images/de-twaa/middle12-2x.png" alt="" class="img-last">
                  </div>
                </div>
              </div>
            </div>
            <div>
              <!-- page title -->
              <div :class="{ 'page-top-hk': sysconfig.is_hk }">
                <div class="d-flex">
                  <div class="col home-quotes">
                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col middle-quotes">
                      <div v-if="pos == 0" class="quotes-0">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                      <div v-if="pos == 1">
                        <prompted-symbol v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                    </div>
                  </div>
                  <div class="col home-quotes">
                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col middle-quotes">
                      <div v-if="pos == 2" class="quotes-2">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                    </div>
                  </div>
                  <div class="col home-quotes">
                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col middle-quotes">
                      <div v-if="pos == 3">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                      <div v-if="pos == 4" class="quotes-4">
                        <prompted-symbol v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- home-middle-mb -->
        <section class="home-middle-mb">
          <div class="container">
            <div>
              <div class="w-100">
                <div class="d-flex">
                  <div class="col home-quotes">
                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col middle-quotes">
                      <div v-if="pos == 0" class="quotes-0">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                      <div v-if="pos == 1">
                        <prompted-symbol v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                      <div v-if="pos == 2" class="quotes-2">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                    </div>
                  </div>
                  <div class="col home-quotes">
                    <div v-for="(sym, pos) in prompted_symbols" :key="pos" class="col middle-quotes">
                      <div v-if="pos == 3" class="quotes-3">
                        <prompted-symbol_v4 v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                      <div v-if="pos == 4" class="quotes-4">
                        <prompted-symbol v-if="$version === '4.4' || $version === '4.2'" :symbol="sym" @symbol-selected="gotoTradePage"/>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- home-intro -->
        <section class="home-intro">
          <div class="container">
            <div class="top">
              <div class="intro-left">
                <img src="@/assets/images/de-twaa/home-middle-left.png" alt="" class="intro-left-img">
              </div>
              <div class="intro-right">
                <div class="title">
                  <div>
                    {{ sysconfig.name }}
                  </div>
                  {{ $t('home.home_multi_financial') }}
                </div>
                <div class="title-desp">
                  <li>
                    {{ $t('home.home_real_time') }}
                  </li>
                  <li>
                    {{ $t('home.home_trading_varieties') }}
                  </li>
                  <li>
                    {{ $t('home.home_user_top_security') }}
                  </li>
                  <li>
                    {{ $t('home.home_order_execution') }}
                  </li>
                </div>
                <div class="home-intro-button" @click="gotoMarket">
                  <div>
                    {{ $t('home.home_Spotgoods') }}
                  </div>
                  <div class="register-start">
                    <img src="@/assets/images/de-twaa/transfer.png" alt="">
                  </div>
                </div>
              </div>
            </div>
            <div class="home-intro-desp">
              <div class="rol quotes w-100">
                <div class="intro-sym sym0">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[0]" />
                    <h2>{{ prompted_symbols[0].metadata.baseSymbol || prompted_symbols[0].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[0].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[0].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[0].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
                <div class="intro-sym sym1">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[1]" />
                    <h2>{{ prompted_symbols[1].metadata.baseSymbol || prompted_symbols[1].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[1].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[1].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[1].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rol quotes w-100">
                <div class="intro-sym sym2">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[2]" />
                    <h2>{{ prompted_symbols[2].metadata.baseSymbol || prompted_symbols[2].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[2].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[2].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[2].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
                <div class="intro-sym sym3">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[3]" />
                    <h2>{{ prompted_symbols[3].metadata.baseSymbol || prompted_symbols[3].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[3].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[3].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[3].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
              </div>
              <div class="rol quotes w-100">
                <div class="intro-sym sym4">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[4]" />
                    <h2>{{ prompted_symbols[4].metadata.baseSymbol || prompted_symbols[4].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[4].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[4].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[4].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
                <div class="intro-sym sym5">
                  <div class="sym-top">
                    <token-icon :symbol="prompted_symbols[5]" />
                    <h2>{{ prompted_symbols[5].metadata.baseSymbol || prompted_symbols[5].metadata.name }}</h2>
                  </div>
                  <div class="sym-middle">
                    <img src="@/assets/images/de-twaa/wavy-lines.png" alt="">
                  </div>
                  <div class="sym-desp">
                    <div class="price">{{ '$' + prompted_symbols[5].price }}</div>
                    <div class="sym-desp-right">
                      <img src="@/assets/images/de-twaa/toggle-up.png" alt="" class="sym-desp-right-img">
                      <div :class="prompted_symbols[5].up ? 'price-green' : 'price-red'">{{
                        numShortener(prompted_symbols[5].price_change_pct.split('%')[0]) }}%</div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- home-intro-mb -->
        <section class="home-intro-mb">
          <div class="container">
            <div class="title">
              <div>
                {{ sysconfig.name }}
              </div>
              {{ $t('home.h1') }}
            </div>
            <div class="top home-intro-top">
              <div class="intro-left">
                <img src="@/assets/images/de-twaa/home-middle-left.png" alt="" class="intro-left-img">
              </div>
              <div class="intro-right">
                <div class="title-desp">
                  <li>
                    {{ $t('home.h1_desp') }}
                  </li>
                  <li>
                    {{ $t('home.h1_desp') }}
                  </li>
                  <li>
                    {{ $t('home.h1_desp') }}
                  </li>
                </div>
                <div class="home-intro-button" @click="gotoMarket">
                  <div>
                    {{ $t('home.home_Spotgoods') }}
                  </div>
                  <div class="register-start-dep">
                    <img src="@/assets/images/de-twaa/transfer.png" alt="" id="register-start-dep">
                  </div>
                </div>
              </div>
            </div>
          </div>
        </section>
        <!-- home-desp -->
        <section class="home-desp">
          <div class="container">
            <div class="home-desp-top">
              <div class="home-desp-left">
                <div class="title">
                  {{ $t('home.register_desp', { platform: sysconfig.name }) }}
                </div>
                <div class="title-desp">
                  {{ $t('home.footer_slogen') }}
                </div>
                <!-- <div class="home-top-button" @click="gotoRegister">
                  <div>
                    {{ $t('register.label_register') }}
                  </div>
                  <div class="register-start">
                    <img src="@/assets/images/de-twaa/goto-register.png" alt="">
                  </div>
                </div> -->
              </div>
              <div class="home-desp-right">
                <img src="@/assets/images/de-twaa/home-desp-right-2x.png" alt="">
              </div>
            </div>
          </div>
        </section>
        <section class="home-quotes-dep">
          <div class="container">
            <inline-svg-icons :symbols="symbols" />
            <home-quotes ref="quotes" :symbols="symbols" />
          </div>
        </section>
      </div>
    </section>
  </section>
</template>

<style scoped lang="less">
//home-top-css
img {
  width: 100%;
}

.home-top {
  background-color: #F5F5F6;
}

.top {
  display: flex;
  color: #000;
  background-color: #F5F5F6;
  justify-content: space-between;
}

.down {
  display: flex;
  justify-content: flex-start;
}

.home-top-left {
  width: 35%;
  display: flex;
  flex-direction: column;
  justify-content: flex-start;
  padding: 50px 20px 20px 20px;
}

.home-top-right {
  width: 65%;
}

.title {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 50px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
  margin-top: 200px;
}

.title-desp {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.home-top-button {
  display: flex;
  width: 80%;
  height: 85px;
  background: #0362FA;
  border-radius: 5px 5px 5px 5px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 25px;
  color: #FFFFFF;
  font-style: normal;
  text-transform: none;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
  cursor: pointer;
}

.register-start {
  margin-left: 10px;
}

#register-start-dep {
  width: 30%;
  margin-left: 20px;
  margin-bottom: 0;
}
.home-top-desp1 {
  align-self: center;
}

.home-top-desp2 {
  display: flex;
  align-items: center;
  background: #1D1D1D;
  border-radius: 17px 17px 17px 17px;
  padding: 10px 20px;
  margin-left: 20px;
}
.home-top-child{
  width: 70%;
}
.home-top-desp3 {
  display: flex;
  align-items: center;
  // padding: 2% 4% 2% 4%;
  background: #F5F5F6;
  border-radius: 17px 17px 17px 17px;
  // margin-left: 3%;
  padding: 10px 20px;
  margin-left: 20px;
}

.desp2-title {
  color: #fff;
  font-weight: 600;
  font-size: 20px;
}

.desp3-title {
  color: #000;
  font-weight: 600;
  font-size: 30px;
}

.desp2-title-desp2 {
  color: #666666;
  font-weight: normal;
  margin-left: 5px;
}
.col.home-quotes {
  width: 50%;
}

.desp2-register {
  color: #fff;
  font-weight: bold;
  font-size: 16px;
}

.desp3-register {
  color: #666666;
  font-weight: bold;
  font-size: 20px;
}
.small-size{
  font-size:10px;
}
.home-top-desp2-img {
  width: 25%;
  margin-left: 10px;
}

.desp4-img {
  width: 30%;
  margin-left: 10px;
}

.home-top-desp-last {
  position: relative;
}

.home-top-desp4 {
  position: absolute;
  // right: -54px;
  display: flex;
  align-items: center;
  // padding: 30px 40px 30px 40px;
  background: #F5F5F6;
  border-radius: 17px 17px 17px 17px;
  z-index: 2;
  padding: 18px 40px;
  right: 5px;
}
.small-style{
  padding: 26px 40px;
}
.home-top-desp5 {
  position: absolute;
  height: 145px;
  right: -80px;
  display: flex;
  align-items: center;
  background: #F5F5F6;
  z-index: 3;
}

//home-middle-css
.home-middle-mb {
  display: none;
}

.home-middle {
  background-color: #F5F5F6;
}

.home-middle-right {
  width: 65%;
  padding: 50px;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.title-middle {
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 50px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.title-desp-middle {
  font-family: PingFang SC, PingFang SC;
  font-weight: 400;
  font-size: 17px;
  color: #000000;
  text-align: left;
  font-style: normal;
  text-transform: none;
}

.img {
  width: 100%;
  margin: 15px;
}

.img-last {
  width: 100%;
  margin: 15px;
}

.middle-quotes {
  margin: 10px;
}

.quotes-2 {
  margin-top: 175px;
  height: 300px;
}

.quotes-4 {
  height: 300px;
}

.quotes-0 {
  margin-top: 100px;
  height: 300px;
}

//home-intro-css
.home-intro-mb {
  display: none;
}

.home-intro {
  background-color: #F5F5F6;
}

.home-intro-desp {
  display: flex;
}

.intro-left {
  display: flex;
  width: 55%;
}

.intro-right {
  display: flex;
  flex-direction: column;
  padding: 20px;
  width: 45%;
}

.home-intro-button {
  display: flex;
  width: 60%;
  height: 85px;
  background: #0362FA;
  border-radius: 46px;
  font-family: PingFang SC, PingFang SC;
  font-weight: 600;
  font-size: 25px;
  color: #FFFFFF;
  font-style: normal;
  text-transform: none;
  align-items: center;
  justify-content: center;
  margin-top: 50px;
  text-align: center;
  cursor: pointer;
}

.price-green {
  color: #04C424;
}

.price-red {
  color: #e55716;
}

.intro-sym {
  background: #191919;
  border-radius: 20px;
  color: #fff;
  margin: 20px;
  padding: 20px;
}

.sym-top {
  display: flex;
  margin-bottom: 20px;
}

.sym-middle {
  margin-bottom: 20px;
}

.sym-desp {
  display: flex;
  margin-bottom: 20px;
}

.sym-desp-right {
  display: flex;
  align-items: center;
  justify-content: center;
  background: #ECFDF7;
  border-radius: 123px 123px 123px 123px;
  border: 1px solid #DAFBEF;
  margin-left: 10px;
  padding: 5px;
}

.sym-desp-right-img {
  width: 50%;
  height: 65%;
}

.price {
  font-size: 20px;
  color: #fff;
}

.sym0 {
  margin-top: 70px;
}

.sym1 {
  padding-bottom: 150px;
}

.intro-sym.sym4 {
  background: #fff;
  color: #000;
  padding-bottom: 150px;
  margin-bottom: 100px;
}

.sym4 .price {
  color: #000;
}

.intro-sym.sym3 {
  background: #fff;
  color: #000;
  margin-top: 50px;
}

.sym3 .price {
  color: #000;
}

//home-desp-css
.home-desp {
  background-color: #F5F5F6;
  padding-bottom: 80px;
}

.home-desp-top {
  display: flex;
  justify-content: space-between;
}

.home-desp-left {
  width: 45%;
  display: flex;
  flex-direction: column;
  padding: 50px;
}

.home-desp-right {
  width: 55%;
  flex: 1;
}

//home-quotes-dep
.home-quotes-dep {
  display: none;
}


.home-section {
  // margin-top: 30px;
  background: #a3dee6;

  .container.more {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .main-block-wrapper {
    margin-top: 60px;
  }

  .container .more a {
    width: 100px;
    height: 40px;
    border-radius: 20px;
    border: 1px solid #000;
    font-family: PingFang SC, PingFang SC;
    font-weight: 600;
    font-size: 14px;
    color: #000000;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  .container .more {
    display: flex;
    align-items: center;
    justify-content: center;
    margin-top: 30px;
    margin-bottom: 30px;
  }
}

/* ** */
.sm-navs {
  padding-top: 1rem;
  padding-bottom: 1rem;
  text-align: center;
}

.sm-navs a {
  color: #000;
  font-size: 12px;
}

.sm-navs svg.svg-icon {
  display: block;
  height: 1.5rem;
  width: auto;
  margin: auto;
  margin-bottom: 0.5rem;
  fill: var(--primary-base);
}

.svg-box {
  height: 1.5rem;
  position: relative;
  margin-bottom: 0.5rem;
}

.sm-navs svg.svg-icon.notice-svg {
  height: 2.8rem;
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -50%);
}
.down-child{
  width: 21.5%;
}
@media screen and (min-width: 768px) and (max-width: 1024px) {
  .d-md-none {
    display: block !important;
  }
}

@media screen and (min-width: 1024px) and (max-width: 1200px) {

  .home-top-desp4,
  .home-top-desp5 {
    display: none;
  }

  .home-top-button,
  .home-intro-button {
    font-size: 20px;
  }

  .register-start {
    margin-left: 10px;
    margin-bottom: 3px;
  }
}

@media (max-width: 1024px) {
  .img {
    margin: 0;
  }

  .img-last {
    margin: 0;
  }

  .title {
    font-size: 25px;
    margin-top: 0;
  }

  .title-desp {
    font-size: 10px;
  }

  .home-top-left {
    padding: 5px;
  }

  .home-top-button,
  .home-intro-button {
    font-size: 12px;
    height: 50px;
  }

  #register-start-dep {
    width: 30%;
    margin-left: 5px;
    margin-bottom: 0;
  }

  .register-start-top,
  .home-top-desp1,
  .home-top-desp2-mb,
  .home-top-desp4,
  .home-top-desp5,
  .home-middle,
  .home-intro,
  .home-desp {
    display: none;
  }

  .home-middle-mb,
  .home-intro-mb,
  .home-quotes-dep {
    display: flex;
    background: #F5F5F6;
  }

  .home-quotes-dep {
    padding: 30px 0;
  }

  .desp2-title,
  .desp3-title {
    font-size: 10px;
  }

  .desp2-register,
  .desp3-register {
    font-size: 13px;
  }

  .desp2-title-desp2 {
    font-size: 10px;
  }

  .home-top-desp2-img {
    width: 23%;
  }
  .desp4-img{
    width: 30%;
  }
  .down {
    display: flex;
    justify-content: space-between;
  }

  .home-top-desp2,
  .home-top-desp3 {
    padding: 3% 5% 3% 2%;
  }

  .quotes-0,
  .quotes-2,
  .quotes-4 {
    margin-top: 0;
    height: 240px;
  }

  .quotes-3 {
    margin-top: 120px;
  }
}

@media(max-width:767px) {
  .home-top-button {
    font-size: 15px;
    height: 50px;
    min-width: 130px;
  }

  .home-intro-button {
    width: 80%;
    font-size: 10px;
  }

  .intro-left-img {
    width: 100%;
  }

  .intro-right {
    padding: 0;
  }

  .home-intro-top {
    margin-top: 20px;
  }
  .desp2-register{
    font-size: 10px;
    max-width: 68px;
    min-width: 60px;
    word-wrap:break-word;
    word-break: break-all;
  }
  .title{
    font-size: 17px;
  }
  .down-child{
    width: 50%;
  }
}
</style>

<script type="text/javascript">
// import PostsComponent from './Components/Posts.vue';
import SymbolList from "./Components/SymbolList.vue";
import TopBanners from "./Components/TopBanners-4.2.vue";
import HomeNotices from "./Components/HomeNotices.vue";
import HomePosts from "./Components/HomePosts.vue";
// import Features from './Components/Features.vue';
import PromptedSymbols from "./Components/PromptedSymbols.vue";
import PromptedSymbol from './Components/_PromptedSymbol.vue';
import PromptedSymbol_v4 from './Components/_PromptedSymbol-4.1.vue';
import Introduction from "./Components/Introduction-4.2.vue";
import HomeQuotes from "./Components/HomeQuotes.vue";
import HomeCarousel from "./Components/HomeCarousel.vue";
import InlineSvgIcons from "../Components/_InlineSvgIcons.vue";
import PartnerLinks from "./Components/_PartnerLinks-4.2.vue";

// import '@/assets/svg/icon-rocket.svg';
// import '@/assets/svg/icon-team.svg';
import "@/assets/svg/icon-login.svg";
import "@/assets/svg/icon-wallet.svg";
import "@/assets/svg/icon-assets.svg";
// import '@/assets/svg/icon-trust.svg';
// import '@/assets/svg/icon-dollar-coin.svg';
import "@/assets/svg/icon-invite.svg";
import "@/assets/svg/icon-notice.svg";

import "@/assets/svg/v4.2/v4.2-login.svg";
import "@/assets/svg/v4.2/v4.2-wallet.svg";
import "@/assets/svg/v4.2/v4.2-assets.svg";
import "@/assets/svg/v4.2/v4.2-notice.svg";

import { SymbolInfo } from "utilities/SymbolInfo.js";
import TokenIcon from '../Components/TokenIcon.vue';
import { getSymbolManagerAsync, getAppContext } from "utilities/helper";

let g_symbolMap = {};
let g_destoryed = false;
let g_quoteTimerId = 0;

export default {
  components: {
    TopBanners,
    SymbolList,
    HomeNotices,
    HomePosts,
    Introduction,
    PromptedSymbols,
    HomeQuotes,
    HomeCarousel,
    InlineSvgIcons,
    PartnerLinks,
    PromptedSymbol_v4,
    PromptedSymbol,
    TokenIcon
  },

  data() {
    return {
      // All symbols.
      symbols: null,
      // App context
      context: null
    };
  },

  computed: {
    // return a list of prompted symbols
    promptedSymbols: function () {
      let arr = this.symbols.filter(function (s) {
        return s.metadata.prompted && !s.metadata.testOnly;
      });
      return arr;
    },
    prompted_symbols: function () {
      let seenNames = new Set();
      let arr = this.symbols.filter(function (s) {
        if (s.metadata.baseSymbol && !seenNames.has(s.metadata.name)) {
          seenNames.add(s.metadata.name);
          return true;
        }
        return false;
      });


      // Only take up to 6 symbols
      if (arr.length > 6) {
        arr = arr.slice(0, 6);
      }
      return arr;
    }
  },

  created() {
    g_destoryed = false;
    this.context = getAppContext();
    // clear all existing symbols
    g_symbolMap = {};
    this.initPageAsync();
  },

  beforeDestroy() {
    g_destoryed = true;

    // clear all timers
    clearTimeout(g_quoteTimerId);
  },

  methods: {
    initPageAsync: async function () {
      // Read symbols.
      const mgr = await getSymbolManagerAsync();
      const sidMap = {};
      if (mgr) {
        const self = this;

        let arr = [];
        $(mgr.getAllSymbols()).each((index, item) => {
          let sym = new SymbolInfo(item);
          arr.push(sym);

          sidMap[sym.metadata.id] = sym;
        });

        g_symbolMap = Object.freeze(sidMap);
        self.symbols = arr;

        Vue.nextTick(() => {
          // start to subscribe realtime quote data
          self.syncQuotes();
        });
      }
    },

    gotoTradePage: function (sym) {
      let route = sym.getRoutePath();
      this.$router.push({ path: route });
    },
    gotoRegister: function () {
      if(this.context && this.context.profile){
        this.$router.push({ path: '/user' });
      }else{
        this.$router.push({ path: '/user/create' });
      }
    },
    gotoMarket: function () {
      this.$router.push({ path: '/quotes' });
    },

    syncQuotes: function () {
      const self = this;
      // clear existing timer if any
      clearTimeout(g_quoteTimerId);

      const sids = [];
      $(this.prompted_symbols).each((index, sym) => {
        sids.push(sym.metadata.id);
      });

      try {
        const temp = this.$refs.quotes.getVisibleSids();
        if (Array.isArray(temp)) {
          for (let i = 0; i < temp.length; i++) {
            sids.push(temp[i]);
          }
        }
      } catch (err) {
        console.error(err);
      }

      if (sids.length > 0) {
        // Read quotes from server
        self.$http
          .post('/api/v1/quotation/latest', {symbols: sids.join(',')})
          .then((json) => {
            const quotes = json.data;
            if (quotes.length > 0) {
              for (let i = 0; i < quotes.length; i++) {
                const quote = quotes[i];
                const sym = g_symbolMap[quote.id];
                if (sym) {
                  sym.update(quote);
                }
              }
            }
          })
          .then(() => {
            if (g_destoryed === false) {
              // always restart timer
              let delay = 4000 + Math.round(Math.random() * 3000);
              g_quoteTimerId = setTimeout(() => {
                self.syncQuotes();
              }, delay);
            }
          });
      }
    },
    numShortener: function (num) {
      let result;
      if (!isNaN(num)) {
        num = Number(num);
        result = Math.abs(num) > 999 ? Math.sign(num) * (Math.abs(num) / 1000).toFixed(2) + 'k' : Math.sign(num) * Math.abs(num).toFixed(2);
      } else {
        result = num;
      }
      return result;
    }
  }
};
</script>
